// This sass-file (and its corresponding CSS-file) is used to describe
// site-specific deviations from the lindholmen_v2-theme (say custom headers, and such).
//
// For convenience (and due to the way the domains was set ut when linholmen.se was
// first built) we use a single style-file for ALL lindholmen_v2s' custom variations.
// For small tweaks (like a custom logo in the header) this tradeoff is acceptable. But
// please reconsider if the tweaks require large Sass-writes since this will be included on ALL
// project-sites.

@import "config.scss";
@import "mixins.scss";

$tweakpoints: (
  "footer-grid-3-switch": 900px,
);

// Civic
.domain-civic {

  .header__mobile-lindholmen-logo,
  .header__desktop-lindholmen-logo {
    display: none;
  }

  .header__container {
    background: $white;

    @include breakpoint("tablet") {
      padding-bottom: 24px;
    }
  }

  .header__site-link,
  .language-switch__link,
  .navigation__mobile-toggle-label {
    color: $black;
  }

  .navigation__mobile-hamburger {
    background: $black;

    &::before,
    &::after {
      background: $black;
    }
  }

  &.mobile-nav--is-open .navigation__mobile-hamburger {
    background: transparent;
  }

  .header__site-link {
    background: url("../../images/site-specific/civic-logo.png") no-repeat;
    background-size: 100%;
    display: block;
    height: 32px;
    margin-top: 5px;
    text-indent: -99999px;
    width: 64px;

    @include breakpoint("tablet") {
      height: 48px;
      width: 95px;
    }

    @include breakpoint("laptop") {
      height: 64px;
      width: 127px;
    }

  }
}

// Mimic
.domain-mimic {

  .header__mobile-lindholmen-logo,
  .header__desktop-lindholmen-logo {
    display: none;
  }

  .header__container {
    background: $white;

    @include breakpoint("tablet") {
      padding-bottom: 24px;
    }
  }

  .header__site-link,
  .language-switch__link,
  .navigation__mobile-toggle-label {
    color: $black;
  }

  .navigation__mobile-hamburger {
    background: $black;

    &::before,
    &::after {
      background: $black;
    }
  }

  &.mobile-nav--is-open .navigation__mobile-hamburger {
    background: transparent;
  }
}

// ElectriCity
.domain-electricity-lindholmen-se {

  .header__mobile-lindholmen-logo,
  .header__desktop-lindholmen-logo {
    display: none;
  }

  .header__container {
    background: $white;

    @include breakpoint("tablet") {
      padding-bottom: 24px;
    }
  }

  .header__site-link,
  .navigation__mobile-toggle-label {
    color: $site-default;
  }

  .language-switch__link {
    color: $white;

    @include breakpoint("tablet") {
      color: $site-default;
    }
  }

  .navigation__mobile-hamburger {
    background: $site-default;

    &::before,
    &::after {
      background: $site-default;
    }
  }

  &.mobile-nav--is-open .navigation__mobile-hamburger {
    background: transparent;
  }

  .custom-mod__clearfix {
    clear: left;
  }
}

// DriveSweden
.domain-drivesweden {

  .header__mobile-lindholmen-logo,
  .header__desktop-lindholmen-logo {
    display: none;
  }

  .header__container {
    background: $white;

    @include breakpoint("tablet") {
      padding-bottom: 24px;
    }
  }

  .header__site-link,
  .navigation__mobile-toggle-label {
    color: $site-default;
  }

  .language-switch__link {
    color: $white;

    @include breakpoint("tablet") {
      color: $site-default;
    }
  }

  .navigation__mobile-hamburger {
    background: $site-default;

    &::before,
    &::after {
      background: $site-default;
    }
  }

  &.mobile-nav--is-open .navigation__mobile-hamburger {
    background: transparent;
  }

  .custom-mod__clearfix {
    clear: left;
  }

}

// Software Development Day
.domain-softwareday {

  .header__site-name {
    @include font-size(24px, 1.1);

    max-width: 68%;

    @include breakpoint("phablet") {
      @include font-size(32px, 1.2);
    }

    @include breakpoint("tablet") {
      @include font-size(37px, 1.1);

      margin-top: -4px;
      width: 64%;
    }

    @include breakpoint("swd-logo-1") {
      @include font-size(48px, 1.1);
    }

    @include breakpoint("desktop") {
      @include font-size(48px, 1.1);

      margin-top: 10px;
      max-width: 78%;
      width: 100%;
    }

    @include breakpoint("swd-logo-2") {
      @include font-size(56px, 1.1);

      margin-top: 2px;
    }
  }

  .header__site-link {
    img {
      @include breakpoint("laptop") {
        max-height: none;
        max-width: 600px;
      }
    }
  }

  .secondary-pusher__prefix {
    color: rgba(255, 255, 255, .5);
  }

  .content__body--frontpage {
    margin-bottom: 35px;

    p {
      line-height: 1.5;
    }
  }
}

// Visual Arena
.domain-visualarena {

  // Custom top header background.
  .header {
    background-color: transparent;
    background-image: url("../../images/site-specific/visualarena-header-bg.jpg");
    background-position: 0 -380px;
    background-repeat: no-repeat;
    background-size: 2560px;

    @include breakpoint("desktop") {
      background-position: 0 -330px;
    }

    .front &.primary-pushers__count--1 {

      @include breakpoint("pusher-switch-3") {
        padding-bottom: 350px;
      }
    }
  }

  &.mobile-nav--is-open .language-switch--mobile .language-switch__link {
    margin-top: 20px;
  }


  // Semi-transparent colors in the header because of the background image.
  .navigation__mobile-button {
    background-color: rgba(0, 0, 0, .2);
  }

  .mobile-menu__wrapper {

    .navigation__menu-tree {

      background-color: $visualarena-mobile-menu-bg;

      @include breakpoint("tablet") {
        background-color: transparent;
      }

      .leaf,
      .expanded,
      .collapsed {
        a {
          &.active,
          &.active-trail {
            background: $visualarena-active-menu-item;
          }

          &:hover {
            background: $visualarena-active-menu-item;
          }
        }
      }
    }

    .navigation__hidden-menu-links {
      .leaf,
      .expanded,
      .collapsed {
        a {
          &.active,
          &.active-trail {
            background-color: rgba(0, 0, 0, .1);
          }

          &:hover {
            background-color: rgba(0, 0, 0, .1);
          }
        }
      }
    }
  }

  .primary-pushers__container {

    &.primary-pushers__count--1 {
      @include breakpoint("pusher-switch-3") {
        padding-bottom: -350px;
      }
    }
  }

  .wrapper--content {

    .front & {
      &.primary-pushers__count--1 {

        @include breakpoint("pusher-switch-3") {
          padding-top: 140px;
        }
      }
    }
  }

  // White border around primary pushers.
  .primary-pusher__grid .primary-pusher {
    border: 2px solid $white;
  }

  .primary-pusher__grid--2-2 .primary-pusher,
  .primary-pusher__grid--3-2 .primary-pusher,
  .primary-pusher__grid--3-3 .primary-pusher {
    border-top: 0;
  }

  @include breakpoint("pusher-switch--count-2") {
    .primary-pusher__grid--2-2 .primary-pusher {
      border-left: 0;
      border-top: 2px solid $white;
    }
  }

  @include breakpoint("pusher-switch-2") {
    .primary-pusher__grid--3-3 .primary-pusher {
      border-left: 0;
    }
  }

  @include breakpoint("pusher-switch-3") {
    .primary-pusher__grid--3-2 .primary-pusher {
      border-left: 0;
      border-top: 2px solid $white;
    }
  }

  .secondary-pusher__prefix {
    color: rgba(255, 255, 255, .6);
  }

}

// Manusfabriken
.domain-manusfabriken {
  font-family: 'Droid Serif', 'Georgia', serif;

  .header__container {
    background: $white;
  }

  .header__mobile-lindholmen-logo,
  .header__desktop-lindholmen-logo {
    display: none;
  }

  .header__site-name {
    float: none;

    @include breakpoint("phablet") {
      float: left;
    }
  }

  .navigation__mobile-button {
    float: left;

    @include breakpoint("phablet") {
      float: right;
      margin-top: 18px;
    }
  }

  .navigation__mobile-toggle-label {
    color: $black;
  }

  .language-switch__link {
    color: $white;

    @include breakpoint("tablet") {
      color: $black;
    }
  }

  .navigation__mobile-hamburger {
    background: $black;

    &::before,
    &::after {
      background: $black;
    }
  }

  &.mobile-nav--is-open .navigation__mobile-hamburger {
    background: transparent;
  }

  @include breakpoint("", "tablet") {
    .wrapper--header {
      padding-bottom: 25px;
    }
  }

  .header__site-link {
    background: url("../../images/site-specific/manusfabriken-logo-mobile.png") no-repeat;
    background-size: 100%;
    display: block;
    height: 53px;
    margin-bottom: 10px;
    margin-top: 5px;
    text-indent: -99999px;
    width: 280px;

    @include breakpoint("laptop") {
      background-image: url("../../images/site-specific/manusfabriken-logo-desktop.png");
      height: 60px; // 2520x352
      margin-bottom: 20px;
      width: 430px;
    }
  }

  &.page-forfattare .main-content {
    @include breakpoint("footer-grid-3-switch") {
      width: 100%;
    }
  }

  &.front .wrapper--content {
    padding-top: 0;
  }

  &.front .content__title {
    margin-bottom: 22px;
  }

  .content__body a {
    color: #d0021b;

    &:hover {
      text-decoration: underline;
    }
  }

  .main-content .cta {
    background: #d0021b;
    color: #fff;
    font-family: "apercu-regular", "Helvetica", sans-serif;
    padding: 15px 30px 10px;
    text-decoration: none;
  }

  .content__body .cta:hover,
  .content__body .cta-arrow:hover,
  .content__body .cta-arrow-short:hover {
    background-color: #8a0101;
    text-decoration: none;
  }

  .secondary-pusher__prefix {
    color: rgba(255, 255, 255, .6);
  }

  .main-content .content__blog-list .news__super-link {
    color: #333;
  }
}

// Film & TV
.domain-film {
  &.page-our-projects .main-content,
  &.node-type-resource-film-tv .main-content {
    width: 100%;
  }

  &.page-our-projects .sidebar,
  &.node-type-resource-film-tv .sidebar {
    display: none;
  }
}
