$tweakpoints: (
  "pusher-switch-1": 560px,
  "pusher-switch--count-2": 700px,
  "pusher-switch-2": 980px,
  "pusher-switch-3": 1230px,
);

.video-container {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;

  iframe,
  object,
  embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.video-container--node {
  margin-bottom: 16px;
}

.video-container--pusher {
  padding-bottom: 220px;
  padding-top: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 10;

  .primary-pusher__grid--1-1 & {
    @include breakpoint("pusher-switch-1") {
      padding-bottom: 320px;
    }

    padding-bottom: 180px;
  }

  @include breakpoint("pusher-switch--count-2") {
    .primary-pusher__grid--2-1 &,
    .primary-pusher__grid--2-2 & {
      padding-bottom: 362px;
    }
  }

  @include breakpoint("pusher-switch-2") {

    .primary-pusher__grid--1-1 & {
      padding-bottom: 420px;
    }

    .primary-pusher__grid--3-1 &,
    .primary-pusher__grid--3-2 &,
    .primary-pusher__grid--3-3 & {
      padding-bottom: 272px;
    }
  }

  @include breakpoint("pusher-switch-3") {

    .primary-pusher__grid--1-1 &,
    .primary-pusher__grid--2-1 &,
    .primary-pusher__grid--2-2 & {
      padding-bottom: 460px;
    }

    .primary-pusher__grid--3-1 & {
      padding-bottom: 462px;
    }

    .primary-pusher__grid--3-2 &,
    .primary-pusher__grid--3-3 & {
      padding-bottom: 230px;
    }

  }
}

.video__play-button {
  padding-left: 25px;

  &::before {
    border-bottom: 7px solid transparent;
    border-left: 7px solid rgba(255, 255, 255, 1);
    border-top: 7px solid transparent;
    content: "";
    display: block;
    float: left;
    height: 7px;
    position: relative;
    right: 8px;
    top: 6px;
    width: 7px;
  }
}

.primary-pusher__show-only-image .video__play-button {
  background: rgba(0, 0, 0, .25);
  display: block;
  height: 100%;
  left: 0;
  padding-left: 0;
  position: absolute;
  top: 0;
  width: 100%;

  &::before {
    content: none;
  }

  .video__play-icon {
    @include image-2x("../../images/video-play-button@2x.png", 72px, 72px);

    background: url("../../images/video-play-button.png") no-repeat 0 0;
    height: 72px;
    left: 50%;
    position: absolute;
    text-indent: -9999999px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 72px;

  }
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
