.grid-item {
  background-color: $darkgrey;
  background-position: center;
  background-size: cover;
  display: block;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  .main-content & {
    color: $white;
  }

  &__content {
    background: rgba(0, 0, 0, .4);
    bottom: 0;
    left: 0;
    padding: 20px;
    position: absolute;
    width: 100%;

    &::before {
      background: linear-gradient(to top, rgba(0, 0, 0, .4) 0%, transparent 100%);
      content: " ";
      display: block;
      height: 2rem;
      left: 0;
      position: absolute;
      top: -2rem;
      width: 100%;
    }
  }

  &__title {
    color: #fff;
    font-size: 1.3rem;
    line-height: 1.8rem;
  }

  &__byline {
    font-size: .9rem;
    line-height: 1.5;
    margin-bottom: 0;
  }
}
