.content__body {
  .faq-question {
    color: inherit;
    // font-size: 32px;
    font-size: 1.5rem;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 1.1875;
    border-bottom: 1px solid #D8D8D8;
    padding: 1.4375rem 0;
    margin-bottom: 1.4375rem;
    &::before {
      content: '\002B';
      color: #333333;//#FECB00;
      margin-right: 0.6rem;
    }
    &.is-first {
      border-top: 1px solid #D8D8D8;
    }

    &.is-open {
      border-bottom: 0;
      margin-bottom: 0;
      &::before {
        content: '\2212';
      }
    }
  }
  .faq-answer {
    font-size: 1.3rem;
    line-height: 1.6;
    margin: 0;
    padding-bottom: 1.4375rem;
    &.is-last {
      margin-bottom: 1.4375rem;
      border-bottom: 1px solid #D8D8D8;
    }
    + .faq-question {
      margin-top: -1.4375rem;
    }
  }
}
