.forum-topic-header-wrapper {
  position: relative;

  .forum-topic__subscribe-link {
    float: right;
  }
}

.form-item-node-notify-subscribe {
  margin-bottom: 15px;
}

.forum-topic-header .topic-post-count {
  float: left;
  margin: 10px 0;
}

div.forum-post {

  border: 0;
  margin-bottom: $margin;

  .forum-post-info {
    padding: calc($padding / 4) calc($padding / 2);

    background: $tablehead;
    border: 1px solid #C1C1C1;
    border-bottom: 0;

    .forum-posted-on,
    .forum-post-number {
      margin: 0;
      padding: 0;

      @include font-size(12px, 1.2);
    }
  }

  .forum-post-wrapper {
    border: 1px solid #C1C1C1;

    .forum-post-panel-sub {
      float: none;
      width: 100%;

      @include breakpoint("tablet") {
        float: left;
        width: 200px;
      }

      .author-pane {
        margin: 0;
        padding: calc($padding / 2);

        span.username {

        }
      }
    }

    .forum-post-panel-main {
      border-left: none;
      min-height: 0;
      margin-left: 0;

      @include breakpoint("tablet") {
        margin-left: 200px;
        border-left: 1px solid #c1c1c1;
      }

      .forum-post-title,
      .post-title {
        padding: calc($padding / 2);
        font-weight: bold;
        border-bottom: 1px solid #c1c1c1;

        @include breakpoint("tablet") {
          padding: calc($padding / 4) $padding;
        }
      }

      .forum-post-content,
      .post-edited {
        padding: $padding calc($padding / 1.33);

        @include breakpoint("tablet") {
          padding: calc($padding / 1.33) $padding;
        }
      }

      .post-edited {
        @include font-size(14px, 1.3);
        margin: 0;
        padding: $padding calc($padding / 1.33);

        @include breakpoint("tablet") {
          padding: calc($padding / 1.33) $padding;
        }
      }
    }
  }

  .forum-post-footer {
    //padding: calc($padding / 4) calc($padding / 2);
    border-top: 0;

    .page-comment-reply & {
      display: none;
    }

    .forum-jump-links {
      display: none;
    }

    .forum-post-links {
      float: none;
    }
  }
}

.forum-post-content {

  ul,
  ol {
    margin-bottom: 16px;

    li {
      background: url("../../images/list-style-image-micro.png") no-repeat 0 8px;
      margin-bottom: .444444444em;
      margin-left: .444444444em;
      list-style: none;
      padding-left: .944444444em;
    }
  }

  ol li {
    list-style: decimal;
  }

  blockquote {
    background: #fff;
    border-left: 3px solid #ccc;
    box-shadow: 0 0 0 1px #ccc;
    color: #444;
    display: inline-block;
    @include font-size(14px, 1.3);
    margin: 16px 2px;
    max-width: 600px;
    padding: 12px 24px;

    p:last-child {
      margin-bottom: 0;
    }
  }

}

.forum-post__attached-content {
  margin-top: 20px;
}

.forum-post__image-thumb {
  box-sizing: border-box;
  display: block;
  float: left;
  padding: 0px 10px 0px 0px;
  width: 50%;
}

.comment-form .field-type-text-long .filter-wrapper,
.node-forum-form .field-type-text-with-summary .filter-wrapper {
  display: none;
}

.node-forum-form .field-type-text-with-summary .ckeditor_links,
.node-ads-form .field-type-text-with-summary .ckeditor_links,
.comment-form .field-type-text-long .ckeditor_links {
  display: none !important;
}

.comment-form .field-type-image,
.node-forum-form .field-type-image,
.comment-form .field-type-file,
.node-forum-form .field-type-file {
  border: 1px solid #c1c1c1;
  margin: 16px 0;
  padding: 16px;

  label {
    display: none;
  }

  .tabledrag-toggle-weight-wrapper {
    display: none;
  }

  .tabledrag-hide {
    display: none;
  }

  tr {

    .form-type-select {
      margin-bottom: 0;
    }
  }

  .even {
    background: #fafafa;
  }

  .odd {
    background: #fff;
  }

  th {
    background: #eee;
    border-bottom: 1px solid #e1e1e1;
    padding: 7px;
    text-align: left;
  }

  td {
    padding: 5px;
    vertical-align: middle;

    .image-preview {
      display: none;

      @include breakpoint("tablet") {
        display: block;
        padding-bottom: 0;

        img {
          display: block;
        }

      }
    }
  }
}

.node-forum-form .field-type-file,
.comment-form .field-type-file {
  margin-top: 0;
}

.comment-form {

  .form-item-notify {
    margin: 5px 0;

    .form-checkbox,
    .option {
      display: inline;
    }

    .option {
      @include font-size(12px, 1.3);

      font-weight: 500;
    }

  }

  #edit-notify-type  .form-item-notify-type { // ID needed to override comment_notify module css.
    display: block;
    margin-bottom: 0;

    label {
      @include font-size(13px, 1.3);
    }

  }

}

.googtube-embed-wrapper {
  margin-bottom: 10px;
  max-height: 250px;
  max-width: 400px;
}

.googtube-embed {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%; /* 16/9 ratio */
  padding-top: 30px; /* IE6 workaround*/
  position: relative;
}

.googtube-embed iframe {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform-style: preserve-3d; /* iOS workaround */
  width: 100%;
}

.attached-images {
  margin-bottom: 10px;
}

.forum__attached-files-header {
  margin-bottom: 4px;
}
