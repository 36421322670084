.event__list-item--teaser {
  margin-bottom: 20px;
}

.event__list-item--last {
  margin-bottom: 0;
}

.event__title--teaser {
  @include font-size(20px, 1.4);
}

.event__title--list {
  @include font-size(26px, 1.2);

  font-weight: 400;
  margin-bottom: 5px;
}

.event__title--full {
  @include font-size(46px, 1.2);

  font-weight: 300;
  margin-bottom: 20px;
}

.event__label {
  @include font-size(26px, 1.2);

  font-weight: 300;
  margin-bottom: 2px;

  a {
    color: $text-body;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.event__organizers {
  @include font-size(14px, 1.4);
}

.event__organizers--teaser {
  margin-top: 8px;
}

.event__organizers--full {
  @include font-size(17px, 1.3);
}

.event__organizer {
  font-family: "apercu-medium";
}

.event__show-more-link {
  color: $link-body;
  float: right;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &::before {
    content: "\e60d";
    float: right;
    font-family: "Lindholmen icons";
    padding-left: 5px;
    position: relative;
    top: 2px;
  }
}
