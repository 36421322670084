.site-intro__container {
  float: left;
  width: 100%;

  h2 {
    @include font-size(26px, 1.3);

    color: $white;
    font-weight: 400;
    margin: 0 0 10px;
    text-align: left;

    @include breakpoint("tablet") {
      @include font-size(46px, 1.2);

      display: block;
    }
  }

  p {
    @include font-size(16px, 1.3);

    color: $white;
    display: inline-block;
    max-width: 790px;
    padding: 0 6px;
    text-align: left;

    @include breakpoint("tablet") {
      @include font-size(18px, 1.3);
    }
  }
}
