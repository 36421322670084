#forum-statistics {
  border: 1px solid #c1c1c1;
}

#forum-statistics-header {
  background: $tablehead;
  border-top: 0;
  @include font-size(20px, 1.3);
  padding: 12px;
}

.forum-statistics-sub-header {
  padding: 8px 16px;
}

.forum-statistics-sub-body {
  background: $odd;
  padding: 8px 24px;
}
