.admin-tabs {
  @extend %clearfix;

  margin: 12px 0;

  .tabs {
    background: #f8f8f8;
    border: 1px solid #ededed;
    border-radius: 3px;

    @include breakpoint("tablet") {
      float: left;
    }
  }

}

.admin-tabs__list-item {
  border-bottom: 1px solid #ededed;

  @include breakpoint("tablet") {
    border-bottom: 0;
    border-right: 1px solid #ededed;
    float: left;

    &:last-child {
      border-right: 0;
    }

  }

  &:first-child {

    .admin-tabs__link {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;

      @include breakpoint("tablet") {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        border-top-right-radius: 0;
      }
    }

  }

  &:last-child {
    border-bottom: 0;

    .admin-tabs__link {
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;

      @include breakpoint("tablet") {
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
      }
    }
  }
}

.admin-tabs__link {
  color: $link-primary;
  display: block;
  padding: 5px 10px;
  text-decoration: none;

  @include breakpoint("tablet") {
    padding: 10px 15px;
  }

  &:hover {
    background: #f0f0f0;
    color: darken($link-primary, 20%);
  }

  &:focus {
    background: $link-primary;
    color: #fff;
    outline: 0;
  }
}
