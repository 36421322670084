// All colors should be declared as variables to ease re-use across
// the project and avoid small variations. Also, consider the darken()
// and lighter() Sass-functions before adding minor tweaks to an
// existing color as a new variable.

$white: #fff !default;
$black: #000 !default;
$grey: #999 !default;
$darkgrey: #666 !default;
$blackgrey: #333 !default;
$petrol: #6f93a1 !default;
$orange: #ff6900 !default;
$green: #4aa88d !default;
$blue: #001a64 !default;
$darkblue: #000d37 !default;
$red: #ff153c !default;

// Migrated from lindholmen_v2_lindholmen theme
$turquoise: #10E9F0 !default;
$cyan: #53E5AF !default;
$light-gray: #F7F8FA !default;

$lindholmen-default: #00a1a1 !default;
$ict-yellow: #f8c959 !default;
$transport-green: #2da57d !default;
$media-red: #d93e3c !default;
$visual-arena-pink: #fa0f96 !default;
$visual-arena-purple: #920f48 !default;
$swiftly-green-blue: #55b2de !default;
$electricity-green: #009ee2 !default;
$electricity-challenge-green: #54a644 !default;
$drivesweden-blue: #55b2de !default;
$civic-teal: #006b80 !default;
$mimic-green: #8fa689 !default;
$combine-project-blue: #014f76 !default;
