.call-out-box {
  background: $light-gray;
  border-left: 4px solid $cyan;
  border-radius: $border-radius;
  font-family: "apercu-regular";
  margin: 3rem 0;
  padding: 2rem 2.2rem 1.8rem;

  p {
    margin: 0 0 1.5rem;

    &:last-child {
      margin: 0;
    }
  }

  a {
    color: $darkblue;
    font-family: "apercu-medium";
  }
}
