$tweakpoints: (
  "2-col": 650px,
);


.contacts__list-item {
  margin-bottom: 40px;
  width: 100%;

  @include breakpoint("2-col") {
    width: 45.3125%;
  }
}

.contact__image {
  max-width: 170px;
}

.contact__role {
  color: #777;
}

.contact__role-description {
  font-size: 1rem;
  line-height: 1.3rem;
  margin-bottom: 1rem;
  max-width: 240px;
  width: 100%;
}

.contact__name {
  font-weight: 700;
}

.contact__phone,
.contact__email {
  @include font-size(16px, 1.3);

  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: $site-default;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

  }

}

.contact__phone {
  margin-top: 8px;
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
