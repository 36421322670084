$tweakpoints: (
  "news__2-col": 580px,
);

.news--view {     // Q: dafuq? a modifier-selector?
  float: left;    // A: Yes. 'cause the Drupal class-sanitizer (that views uses) is whack.
}                 // See https://www.drupal.org/node/2427925 for more info.

.news__grid-1 {
  margin-right: -100%;
  width: 100%;

  @include breakpoint("news__2-col") {
    clear: left;
    float: left;
    width: 47.7791878%;
  }
}

.news__grid-2 {
  margin-right: -100%;
  width: 100%;

  @include breakpoint("news__2-col") {
    float: left;
    margin-left: 52.2208122%;
    width: 47.7791878%;
  }
}

.news__grid-first {
  clear: none;
}

.news__list-item {
  border-bottom: 1px solid $border-color;
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.main-content a.news__item:hover { // scss-lint:disable QualifyingElement
  text-decoration: none;

  .news__title {
    text-decoration: underline;
  }
}

.news__title {
  @include font-size(26px, 1.2);

  font-family: "apercu-regular";
  font-weight: 400;
  margin-bottom: .5rem;

  .news__item--teaser & {
    color: $link-title;
  }

  .news__item--featured & {
    color: $darkgrey;
  }
}

.news__date,
.news__date-created {
  @include font-size(14px, 1.2);

  color: $meta-info;
  display: block;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

.news__image-wrapper {
  position: relative;
}

.news__label {
  @include font-size(14px, 1.2);

  background: $teaser-overlay-label-background;
  color: $teaser-overlay-label-text;
  display: inline;
  padding: 3px 6px;
  text-transform: uppercase;
}

.news__label--featured,
.news__label--super {
  display: block;
  left: 0;
  position: absolute;
  top: 10px;
  z-index: 10;
}

.post__meta--teaser {
  display: inline;
}

.post__teaser {
  color: $text-body;
  font-family: "tiempos-regular", "Georgia", serif;
  line-height: 1.5;
}

.news__label--super {
  @include font-size(16px, 1.2);

  top: 40px;
}

.news__label-wrapper {
  @include font-size(15px, 1.2);

  color: $meta-info;
  display: inline;
}

.news__label--teaser {
  @include font-size(13px, 1.2);

  background: $teaser-overlay-label-background;
  border-radius: 2px;
  color: $teaser-overlay-label-text;
  display: inline;
  margin-left: 2px;
  padding: 4px 5px 2px 6px;
}

.news__super-list {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
  padding-bottom: 30px;
}

.news__super-list-item {
  background: $news-super-bg;
  padding: 20px 20px 40px;

  .news__super-link {
    color: $white;
  }
}

.blog__super-list-item {
  padding: 20px 20px 0;
}

.news__super-title {
  @include font-size(32px, 1.2);

  font-weight: 400;
  margin-bottom: 5px;
}

.news__super-teaser {
  color: $text-preamble;

  .blog__super-list-item & {
    color: $text-body;
  }
}

.news__video--super {
  margin-bottom: 10px;
  position: relative;
}

.news__more-link-wrapper {
  @extend %clearfix;

  background: $more-link-background;
  float: left;
  margin-bottom: 60px;
  width: 100%;
}

.news__more-link {

  .main-content & {
    @include font-size(22px, 1.2);

    color: $more-link-color;
    float: right;
    padding: 11px 16px 8px;
  }

  &::before {
    @include font-size(18px, 1.2);

    content: "\e60d";
    float: right;
    font-family: "Lindholmen icons";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    left: 6px;
    position: relative;
    text-transform: none;
    top: 3px;
  }
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
