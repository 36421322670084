.blog-type__header::after {
  clear: both;
  content: " "; // Older browser do not support empty content
  display: block;
  height: 0;
  visibility: hidden;
}

.blog-type__title {
  float: left;
}

.blog-type__feed {
  float: right;
}
