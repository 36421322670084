
@function calculateRem($size) {
  $remSize: calc($size / 16px);
  @return $remSize * 1rem;
}

@mixin font-size($size, $lineHeight) {
  font-size: $size;
  font-size: calculateRem($size);
  line-height: $lineHeight;

  // TODO: rename the mixin and make it work even if you exclude the lineHeight parameter.
}

@mixin breakpoint($from:"", $to:"") {

  @if ($responsive == true) {

    @if ($from == "" and not ($to == "")) {
      // Min-width is not specified, but max-width seems to be.
      @if map-has-key($breakpoints, $to) {
        @media (max-width: #{map-get($breakpoints, $to)}) {
          @content;
        }
      }
      @else {
        @warn "Hmmm, the `$to`-breakpoint you've specified can't be found in the `$breakpoints` map. "
            + "Please check your spelling and/or revisit _config.scss to clear things up.";
      }
    }

    @if (not ($from == "") and not ($to == "")) {
      // Min-width AND max-width is specified.
      @if map-has-key($breakpoints, $from) and map-has-key($breakpoints, $to) {
        @media (min-width: #{map-get($breakpoints, $from)}) and (max-width: #{map-get($breakpoints, $to)}) {
          @content;
        }
      }
      @else {
        @warn "Derp, either the `$from`- or the `$to`-breakpoint you've specified can't be found in the `$breakpoints` map. "
            + "Please check your spelling and/or revisit _config.scss to clear things up.";
      }
    }

    @if (not ($from == "") and $to == "") {
      // This would be a normal, mobile-first'y breakpoint with only a min-width specified.
      @if map-has-key($tweakpoints, $from) {
        @media (min-width: #{map-get($tweakpoints, $from)}) {
          @content;
        }
      }
      @else if map-has-key($breakpoints, $from) {
        @media (min-width: #{map-get($breakpoints, $from)}) {
          @content;
        }
      }
      @else {
        @warn "Ok champ, the breakpoint you've specified can't be found in the `$breakpoints` map. "
            + "Please check your spelling and/or revisit _config.scss to clear things up.";
      }
    }
  }
  @else {
    // $responsive is set to false, meaning we want to rasterize
    // the output outside @media blocks.
    @if ((#{map-get($breakpoints, $from)}) == (#{map-get($breakpoints, $static-breakpoint)})) {
      // If the media query width is the same as the assigned static-breakpoint,
      // output the content without the @media block-wrapper.
      @content;
    }
  }

}

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}
// Usage:
// .container-with-floated-children {
//   @extend %clearfix;
// }

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    // on retina, use image that's scaled by 2
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin retina() {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    @content;
  }
}

@mixin intro {
  font-family: "tiempos-regular";
  font-size: 1.3rem;
  line-height: 1.5;

  @include breakpoint("tablet") {
    font-size: 1.6rem;
  }
}

@mixin content-h2 {
  font-size: 1.75rem;
  line-height: 1.2;
  margin-bottom: .25em;

  @include breakpoint(tablet) {
    font-size: 2.25rem;
  }

  @include breakpoint(desktop) {
    font-size: 2.5rem;
  }
}

@mixin content-h3 {
  font-size: 1.5rem;
  line-height: 1.2;
  margin-bottom: .25em;

  @include breakpoint(tablet) {
    font-size: 1.75rem;
  }

  @include breakpoint(desktop) {
    font-size: 2rem;
  }
}

@mixin content-h4 {
  font-size: 1.25rem;
  line-height: 1.2;
  margin-bottom: .25em;

  @include breakpoint(tablet) {
    font-size: 1.5rem;
  }

  @include breakpoint(desktop) {
    font-size: 1.75rem;
  }
}

@mixin content-h5 {
  font-size: 1rem;
  line-height: 1.2;
  margin-bottom: .25em;

  @include breakpoint(tablet) {
    font-size: 1.25rem;
  }

  @include breakpoint(desktop) {
    font-size: 1.5rem;
  }
}
