$tweakpoints: (
  "footer-grid-switch": 580px,
  "footer-grid-3-switch": 900px,
  "layout__centered": 1420px,
);

.wrapper {
  @extend %clearfix;

  margin: 0 4.6875%;
  max-width: $wrapper-max-width;

  @include breakpoint("tablet") {
    margin: 0 8%;
  }

  @include breakpoint("layout__centered") {
    margin: 0 auto;
  }
}

.wrapper--header {
  height: 100%;
  position: relative;
}

.wrapper--content {
  margin-top: 35px;
  padding-bottom: 15px;
}

.wrapper--footer {
  height: 100%;
}

.main-content {
  float: left;
  margin-right: -100%;
  width: 100%;

  &--has-sidebar {
    @include breakpoint("footer-grid-3-switch") {
      width: 65.6576%;
    }
  }
}

.sidebar {
  clear: left;
  float: left;
  margin-right: -100%;
  width: 100%;

  @include breakpoint("footer-grid-3-switch") {
    clear: none;
    margin-left: 68.5872%;
    width: 31.3639%;
  }

  .block {
    float: left;
    width: 100%;
  }
}

.sidebar__section {
  border-bottom: 1px solid $border-color;
  float: left;
  margin-bottom: 20px;
  padding-bottom: 30px;
  width: 100%;
}

.sidebar__section-header {
  @include font-size(24px, 1.5);

  margin-bottom: 10px;
}

.footer__region--1 {

  @include breakpoint("footer-grid-switch") {
    float: left;
    margin-right: -100%;
    width: 45.3125%;
  }

  @include breakpoint("footer-grid-3-switch") {
    width: 31.3639%;
  }
}

.footer__region--2 {
  float: left;
  margin-left: 34.2936%;
  margin-right: -100%;
  width: 31.3639%;
}

.footer__region--3 {

  @include breakpoint("footer-grid-switch") {
    float: left;
    margin-left: 54.6875%;
    margin-right: -100%;
    width: 45.3125%;
  }

  @include breakpoint("footer-grid-3-switch") {
    margin-left: 68.5872%;
    width: 31.3639%;
  }
}

.footer__region--bottom {
  clear: left;
  float: left;
  width: 100%;
}

.limited-content {
  p {
    max-width: 790px;
  }
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
