.language-switch--mobile {

  .js & {
    display: none;
  }

  .mobile-nav--is-open & {
    display: block;
  }

  .language-switch__link {
    border: 1px solid $white;
    color: $white;
    display: block;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    width: 100%;

    &:hover {
      background: rgba(0, 0, 0, .1);
      border: 1px solid rgba(0, 0, 0, .2);
    }
  }

}

.language-switch--desktop {
  display: none;

  @include breakpoint("tablet") {
    display: block;
    margin-bottom: 0;
    margin-top: 16px;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 20;
  }

  .language-switch__link {
    @include font-size(16px, 1.2);

    color: $white;
    margin-left: 20px;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
}
