//------------------------------------------------------------//
// Remember, this file should contain element-selectors ONLY! //
// Also keep these to a minimum to avoid inheritance-problems //
// further down the path. (see the wiki for more info)        //
//------------------------------------------------------------//

$tweakpoints: (
  "footer-grid-switch": 580px,
  "footer-grid-3-switch":900px,
);

html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); // disable webkit tap highlight
}

html {
  box-sizing: border-box;
  font-size: 80%;
  min-height: 100%;
  position: relative;
  text-size-adjust: 100%;

  @include breakpoint(laptop) {
    font-size: 90%;
  }

  @include breakpoint(large-desktop) {
    font-size: 100%;
  }
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  @include font-size(16px, 1.5);

  @include breakpoint("tablet") {
    @include font-size(18px, 1.75);
  }

  color: $text-body;
  font-family: "apercu-regular", Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;

  @include breakpoint("footer-grid-switch") {
    margin-bottom: $footer--tablet-height;
  }

  @include breakpoint("footer-grid-3-switch") {
    margin-bottom: $footer-height;
  }
}

h1, h2, h3, h4, h5 {
  font-family: "apercu-bold";
  font-weight: normal;
  color: $title-color;
}

p {
  font-family: "apercu-regular";
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 2rem;

  @include breakpoint("tablet") {
    font-size: 1.2rem;
    line-height: 1.6;
  }
}

strong, b {
  font-family: "apercu-bold";
  font-weight: normal;
}

strong,
b {
  font-family: "apercu-bold";
  font-weight: normal;

  em,
  i {
    font-family: "apercu-bold-italic";
    font-style: normal;
  }
}

em,
i {
  font-family: "apercu-italic";
  font-style: normal;

  strong,
  b {
    font-family: "apercu-bold-italic";
    font-weight: normal;
  }
}

.hidden {
  display: none;
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
