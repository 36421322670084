// This is where all those shitty selectors where we can't control the output end up.
// Make sure you've done everything in your power to fix the issue in the preprocessing
// before you end up here. And make sure you feel ashamed as you use this file!!!
//
// Also make sure to clearly document what each selector does, and why it's here instead
// of where it should be.

.content__body img {
  height: auto !important;      // WAIT WHAT! !important? Really?
  max-width: 100% !important;   // Really. Since CKEditor outputs width & height attributes
}

img {
  height: auto;
  max-width: 100%;
}
