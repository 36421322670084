$tweakpoints: (
  "footer-grid-switch": 580px,
  "footer-grid-3-switch":900px,
);

.footer {
  background: $footer-bg;
  border-top: 1px solid $border-color;


  @include breakpoint("footer-grid-switch") {
    bottom: 0;                      // 
    position: absolute;             // sticky footer styles
    width: 100%;                    //
  }

}

.footer__region {
  @include font-size(16px, 1.4);

  margin-top: 42px;

  a {
    color: $link-primary;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer__region--bottom {
  border-top: 1px solid $border-color;
  color: $text-footer;
  margin-top: 20px;
  padding: 14px 0 0;
}

.footer__lindholmen-logo {
  background: url("../../images/lsp-logo--black.svg") no-repeat 0 0;
  background-position: left center;
  background-size: contain;
  display: block;
  height: 82px;
  margin-bottom: 20px;
  text-indent: -9999px;
  width: 174px;
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
