.list-filter {
  list-style: none;
  margin: 0 0 2rem;
  overflow: hidden;
  padding: 0;

  &--center {
    text-align: center;

    .page-events-archive & {
      text-align: left;
    }
  }

  @include breakpoint(tablet) {
    margin: 0 0 4rem;
  }

  @include breakpoint(desktop) {
    margin: 0 0 5.25rem;
  }

  &__item {
    float: left;
    margin-bottom: .5rem;
    margin-right: .5rem;

    @include breakpoint(tablet) {
      margin-bottom: .75rem;
      margin-right: .75rem;
    }

    .list-filter--center & {
        float: none;
        display: inline-block;
    }
  }

  &__btn {
    border-radius: $filter-border-radius;
    border: 2px solid $filter-color;
    color: $filter-color;
    cursor: pointer;
    float: left;
    font-family: "apercu-medium";
    font-size: .9rem;
    line-height: normal;
    outline: none;
    padding: .4rem .8rem;
    position: relative;
    transition: .3s $filter-transition-function;

    @include breakpoint(tablet) {
      font-size: 1rem;
      padding: .5rem 1rem;
    }

    @include breakpoint(desktop) {
      font-size: 1.1rem;
      padding: .65rem 1.5rem;
    }

    &:hover,
    &.is-active {
      background: $filter-color;
      color: $white;
      text-decoration: none !important;
    }

    &:active {
      top: 1px;
    }
  }
}
