.social-share {
  float: left;
  margin: 35px 0 25px;
  width: 100%;

  &::after {
    clear: both;
    content: "";
    display: table;
  }

  &__link {
    display: block;
    float: left;
    font-family: "Lindholmen icons";
    font-size: 0;
    -webkit-font-smoothing: antialiased;
    height: 35px;
    margin-right: 15px;
    width: 35px;

    .main-content & {
      color: $social-share-background;

      &:hover {
        color: $social-share-background-hover;
        text-decoration: none;
      }
    }

    &::before {
      font-size: 35px;
      line-height: 35px;
    }

    &--facebook::before {
      content: "\e604";
    }

    &--twitter::before {
      content: "\e601";
    }

    &--linkedin::before {
      content: "\e603";
    }
  }
}
