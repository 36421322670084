$tweakpoints: (
  "header__menuswitch": 767px,
  "header__menu-full": 900px,
  "layout__centered": 1420px,
);

.header {
  background-color: $site-default;
  position: relative;
}

.header__bg {
  background-position: center;
  background-size: cover;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__gradient {
  height: 100%;
  left: 0;
  opacity: .9;
  position: absolute;
  top: 0;
  width: 100%;
}

.header__container {
  @extend %clearfix;

  // background: red;
  margin-bottom: 20px;
  padding: 12px 4.6875%;
  position: relative;

  @include breakpoint("tablet") {
    float: left;
    margin-bottom: 0;
    padding: 24px 8% 12px;
    width: 100%;
    z-index: 10;
  }

  @include breakpoint("layout__centered") {
    margin: 0 auto;
  }


}

.header__inner-wrapper {
  margin: 0 auto;
  max-width: $wrapper-max-width;
}

.header__mobile-lindholmen-logo {
  @extend %clearfix;

  background: $white;
  max-width: $wrapper-max-width;
  padding: 0 4.6875%;

  @include breakpoint("tablet") {
    display: none;
  }
}

.header__lindholmen-link--mobile {
  color: $black;
  display: block;
  padding: 4px 0;
  text-decoration: none;
}

.header__lindholmen-link--desktop {


  @include breakpoint("tablet") {
    background: url("../../images/lsp-logo.svg") no-repeat 0 0;
    background-position: center center;
    background-size: contain;
    display: block;
    float: left;
    height: 74px;
    text-indent: -9999px;
    width: 174px;

    // .no-svg & {

    // }
  }
}

.header__desktop-lindholmen-logo {
  display: none;

  @include breakpoint("tablet") {
    border-right: 1px solid $white;
    display: block;
    float: left;
    margin-right: 20px;
    padding-right: 20px;

  }
}


.header__site-name {
  @include font-size(32px, 1.2);

  float: left;
  font-weight: 300;

  @include breakpoint("tablet") {
    @include font-size(66px, 1.1);
  }
}

.header__site-link {
  color: $white;
  text-decoration: none;

  img {
    display: block;
    max-width: 200px;

    @include breakpoint("phablet") {
      max-width: 300px;
    }

    @include breakpoint("laptop") {
      max-height: 70px;
      max-width: 600px;
    }

    @include breakpoint("desktop") {
      max-width: 760px;
    }
  }
}

.header__logo {
  @include font-size(24px, 1);

  float: left;

  @include breakpoint("phablet") {
    @include font-size(28px, 1.5);
  }

  @include breakpoint("tablet") {
    @include font-size(36px, 1.5);
  }

}

.mobile-menu__wrapper {
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: all .2s cubic-bezier(.175, .885, .32, 1.275);
  width: 100%;

  .mobile-nav--is-open & {
    margin: 0 0 40px;
    max-height: 800px; // This needs to be a fixed measurement for the mobile menu to work.
    overflow: visible;
  }

  .no-js & {
    max-height: 800px;
    overflow: visible;
  }

  @include breakpoint("tablet") {
    clear: left;
    float: left;
    margin: 20px 0 40px;
    max-height: none;
    opacity: 1;
    overflow: visible;
  }

  .navigation__menu-tree {
    @extend %clearfix;

    opacity: 0;

    .mobile-nav--is-open & {
      animation: mainNavfadeIn .5s;
      opacity: 1;
    }

    @include breakpoint("tablet") {
      opacity: 1;
    }

    // 1nd level
    .leaf,
    .expanded,
    .collapsed {
      @include breakpoint("header__menuswitch") {
        float: left;
      }

      a {
        color: $white;
        display: block;
        padding: 10px 13px 9px;
        text-decoration: none;

        @include breakpoint("tablet") {
          @include font-size(18px, 1.5);
        }

        @include breakpoint("desktop") {
          @include font-size(24px, 1.5);
        }

        &:hover {
          background: rgba(0, 0, 0, .1);
        }

        &:focus {
          background: rgba(0, 0, 0, .1);
          outline: 0;
        }

        &.active,
        &.active-trail {
          background: rgba(0, 0, 0, .2);
        }
      }

      .navigation__show-hidden-links {
        margin-right: 0;
      }

      // 2nd level
      .leaf,
      .expanded,
      .collapsed {

        a {
          padding-left: 26px;
        }

        @include breakpoint("tablet") {
          display: none;
        }

      }

    }

    .expanded {
      background: rgba(0, 0, 0, .1);

      @include breakpoint("tablet") {
        background: transparent;
      }
    }

  }

  .leaf .navigation__hidden-menu-links {
    background: $white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, .3);
    position: absolute;
    right: 0;
    top: 47px;
    white-space: nowrap;
    z-index: 10000;

    @include breakpoint("desktop") {
      top: 50px;
    }

    .leaf,
    .expanded,
    .collapsed {
      display: block;
      float: none;
      opacity: 1;
      width: 100%;
      z-index: 1000;

      a {
        color: $site-default;
        margin-right: 0;
        padding-right: 26px;
      }
    }

    > .expanded {
      display: block;
      width: 100%;

      a {
        display: block;
        margin-right: 0;
        padding-right: 26px;
      }

      .navigation__menu-tree {
        display: none;
      }
    }

    > .collapsed {
      display: block;

      .collapsed {
        display: none;
      }
    }
  }

}



@keyframes mainNavfadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.navigation__mobile-toggle {
  margin-top: 5px;

  @include breakpoint("phablet") {
    margin-top: 6px;
  }

  @include breakpoint("laptop") {
    display: none;
  }

}

.navigation__mobile-button {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  float: right;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  padding: 7px 8px;
  position: relative;
  text-transform: uppercase;

  &:active {
    background: rgba(0, 0, 0, .1);
  }

}

.navigation__mobile-toggle-label {
  float: right;
  line-height: 1;
  margin-right: 24px;
}

.navigation__mobile-toggle-label--on {
  display: none;

  .mobile-nav--is-open & {
    display: block;
  }
}

.navigation__mobile-toggle-label--off {

  .mobile-nav--is-open & {
    display: none;
  }
}

.navigation__mobile-hamburger {
  background: $white;
  border: 0;
  display: inline-block;
  height: 2px;
  margin-bottom: 5px;
  margin-left: 4px;
  position: absolute;
  right: 9px;
  top: 14px;
  transition: .1s;
  width: 16px;

  .mobile-nav--is-open & {
    background: transparent;
  }

  &::before,
  &::after {
    background: $white;
    content: "";
    display: inline-block;
    height: 2px;
    left: 0;
    position: absolute;
    transition: .1s;
    width: 16px;
  }

  &::before {
    top: 5px;

    .mobile-nav--is-open & {
      top: 0;
      transform: rotate3d(0, 0, 1, 45deg);
      transform-origin: 50% 50%;
    }
  }

  &::after {
    top: -5px;

    .mobile-nav--is-open & {
      top: 0;
      transform: rotate3d(0, 0, 1, -45deg);
      transform-origin: 50% 50%;
    }
  }
}

.navigation__arrow {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 13px solid $white;
  display: inline-block;
  height: 0;
  margin-left: 5px;
  width: 0;
}

.js-navigation__show-hidden-links {
  position: relative;
}

.js-navigation__hidden-menu-links--closed {
  display: none;
}

.js-navigation__hidden-menu-links--open {
  display: block;
}

.navigation__show-hidden-links {
  position: relative;
}


/**
  * @name Site-Navigation--Secondary
  * @description Style for the site-navigation--secondary class
  */
.site-navigation--secondary {
  position: absolute;
  right: 60px;
  top: 50%;
  z-index: 1;
  margin-top: -18px;
}
@media only screen and (min-width: 836px) {
  .site-navigation--secondary {
    right: 20px;
    top: -5px;
    margin-top: 0;
  }

}

.site-navigation--secondary li {
  margin-left: 10px;
}
.site-navigation--secondary li.first {
  margin-left: 0;
}
@media only screen and (max-width: 835px) {
  .site-navigation--secondary li {
    float: right;
  }
}

.site-navigation--secondary li > a {
  color: #bbbbbb;
}


// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
