$tweakpoints: (
  "header__switch-mobile": max-width 1023px,
  "header__double-logo": 1430px,
  "header__double-logo-margin":1400px,
);

.header__site-name {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  max-width: 65%;
  @include breakpoint ("tablet") {
    flex-direction: row;
    align-items: center;
    max-width: none;
  }
}

.header__double-logo {
  display: block;
  max-width: 100%;
  max-height: 60px;
  @include breakpoint("tablet") {
    max-height: 70px;
    max-width: 50%;
  }

  // @include breakpoint("phablet") {
  //   max-width: 300px;
  // }

  // @include breakpoint("laptop") {
  //   max-width: 500px;
  //   max-height: 50px;
  // }

  // @include breakpoint("desktop") {
  //   max-width: 500px;
  //   max-height: 50px;
  // }
}

// .header__double-logo {
//   position:relative;
//   max-width: 200px;
//   margin: 0px;

//   @include breakpoint ("phablet") {
//     max-width: 312px;
//   }

//   @include breakpoint ("laptop") {
//     position: absolute;
//     max-height: 108px;
//     max-width:400px;
//     right:8%;
//     top:10%;
//   }

//   @include breakpoint ("desktop") {
//     transform: translateX(0%);
//     margin:0 -20px;
//     left:auto;
//     right:8%;
//   }

//   @include breakpoint("header__double-logo-margin") {
//     max-height:112px;
//     top: 10%;
//     left: 0%;
//     right: 0;
//     max-width: 410px!important;
//     transform: translateX(50%)!important;
//     margin: 0 50%!important;
//   }
// }

.header__site-link {
  color: $white;
  text-decoration: none;
  display: block;
  max-width: 100%;
  width: 264px;
  max-height: 60px;
  @include breakpoint ("tablet") {
    max-width: 50%;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    max-height: 60px;
    // max-width: 200px;

    // @include breakpoint("phablet") {
    //   max-width: 300px;
    // }

    // @include breakpoint("laptop") {
    //   max-width: 500px;
    //   max-height: 50px;
    // }

    // @include breakpoint("desktop") {
    //   max-width: 500px;
    //   max-height: 50px;
    // }
  }
}

.language-switch--desktop{
  position: absolute;
  margin-bottom: 0;
  margin-top: 0px;
  width: auto;
  top: 0px !important;
  float: right;
  text-align: right;
  z-index: 20;
  display: none;
  text-transform: capitalize;

  @include breakpoint("tablet") {
    display: block;
    a {
      .domain-combineproject & {
        color: #666 !important;
      }
    }
  }
  @include breakpoint ("laptop") { // (min-width: 1024px)
    top: 147px !important;
    a {
      .domain-combineproject & {
        color: #fff !important;
      }
    }
  }
  @include breakpoint("desktop") { // (min-width: 1200px)
    top: 151px !important;
  }
  

  // @include breakpoint ("header__switch-mobile") {
  //   display: none !important;
  // }

  @include breakpoint ("phablet") { // (min-width: 416px)
    // position: absolute;
    // display: block;
    // margin-bottom: 0;
    // margin-top: 16px;
    // width: auto;
    // // top: 126px!important;
    // float: right;
    // text-align: right;
    // z-index: 20;
    // top: auto !important;
    // bottom: 164px !important;
  }

  @include breakpoint ("laptop") { // (min-width: 1024px)
  }

  @include breakpoint("desktop") { // (min-width: 1200px)
  }
}

.navigation__mobile-toggle {
  position: absolute;
  right: 10px;
  top: 10px;
  @include breakpoint("tablet") {
    display: none;
  }
}

.navigation__mobile-toggle-label {
  float: right;
  line-height: 1;
  margin-right: 24px;
  position: absolute;
  right:7px;
}

// Combine Project
.domain-combineproject {

  .header__mobile-lindholmen-logo,
  .header__desktop-lindholmen-logo {
    display: none;
  }

  .header__container {
    background: $white;

    @include breakpoint("tablet") {
      padding-bottom: 24px;
    }
  }

  .language-switch__link{
    color: $white;
  }

  .header__site-link,
  .navigation__mobile-toggle-label {
    color: $black;
  }

  .navigation__mobile-hamburger {
    background: $black;

    &::before,
    &::after {
      background: $black;
    }
  }

  &.mobile-nav--is-open .navigation__mobile-hamburger {
    background: transparent;
  }
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
