// Hide plus-sign used to collapse forum listing.

span.forum-collapsible {
  display: none;
}


// .forum-table-forums td {
//   @include font-size(14px, 1.3);
// }

td.forum-details .forum-description{
  @include font-size(12px, 1.3);

  @include breakpoint("tablet") {
    @include font-size(14px, 1.3);
  }
}


// Forum list "bell" icon style

.forum-table thead tr th.forum-icon {
  padding-right: 0;
}

tr.odd td.forum-list-icon-default,
tr.even td.forum-list-icon-default,
tr.odd td.forum-list-icon-new-posts,
tr.even td.forum-list-icon-new-posts {
  &.forum-list-icon {
    padding-right: 0;
    width: 24px;
  }

  .forum-list-icon-wrapper {
    border: none;
    box-shadow: none;
    display: inline-block;
    margin: 0;

    &, span {
      height: 26px;
      width: 26px;
    }

    span {
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}


// No new posts in forum
tr.odd td.forum-list-icon-default,
tr.even td.forum-list-icon-default {
  .forum-list-icon-wrapper span {
    background-image: url('../../images/forum--no-new-posts.png');
  }
}

// New posts in forum
tr.odd td.forum-list-icon-new-posts,
tr.even td.forum-list-icon-new-posts {
  .forum-list-icon-wrapper span {
    background-image: url('../../images/forum--new-posts.png');
  }
}


.forum-list-icon-legend div {
  margin-bottom: calc($margin / 2);
}

.forum-list-icon-legend .forum-list-icon-wrapper {
    border: none;
    box-shadow: none;
    display: inline-block;
    margin: 0;

    &, span {
      width: 26px;
      height: 26px;
    }

    span {
      background-position: center;
      background-repeat: no-repeat;
    }

    .forum-list-icon-new-posts {
      background-image: url('../../images/forum--new-posts.png');
    }
    .forum-list-icon-default {
      background-image: url('../../images/forum--no-new-posts.png');
    }
  }

.view-forum-search .views-exposed-widget .form-submit {
  margin-top: 0;
}
