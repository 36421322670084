.resource-grid {

  &__header {
    p {
      max-width: 790px;
    }
  }

  &__filters {
    .form-item {
      float: left;
      margin-right: 50px;

      &:last-child {
        margin-right: 0;
      }

      .option {
        text-transform: uppercase;

        &:hover {
          cursor: pointer;
        }
      }

      .form-radio {
        display: none;

        &:checked + .option {
          text-decoration: underline;
        }
      }
    }

    .ajax-progress .throbber {
      filter: grayscale(100%);
    }
  }

  &__item {
    float: left;
    padding-bottom: 30px;
    width: 100%;

    @include breakpoint("tablet") {
      width: 50%;

      &:nth-child(2n+1) {
        padding-right: 15px;
      }

      &:nth-child(2n+2) {
        padding-left: 15px;
      }
    }

    @include breakpoint("desktop") {
      width: 33.33%;

      &:nth-child(3n+1) {
        padding-left: 0;
        padding-right: 20px;
      }

      &:nth-child(3n+2) {
        padding-left: 10px;
        padding-right: 10px;
      }

      &:nth-child(3n+3) {
        padding-left: 20px;
        padding-right: 0;
      }
    }
  }
}
