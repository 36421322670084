
@import "colors.scss";

$site-default: $darkgrey !default;

$title-large-color: $darkgrey;
$title-color: $blackgrey;
$link-primary: $blackgrey !default;
$link-title: $link-primary !default;
$link-body: $site-default !default;
$text-preamble: #aaa !default;
$text-body: $blackgrey !default;
$resource-separator: #e3e3e3 !default;
$meta-info: $grey !default;
$news-super-bg: #262626 !default;
$text-footer: $darkgrey !default;
$footer-bg: #fafafa !default;
$border-color: #ebebeb !default;
$section-header: #7f8389 !default;
$secondary-pusher-background: $darkgrey !default;
$secondary-pusher-corner: darken($secondary-pusher-background, 10%) !default;
$secondary-pusher-color: $white !default;
$secondary-pusher-prefix: $grey !default;
$teaser-overlay-label-background: $site-default !default;
$teaser-overlay-label-text: $white !default;
$date-box-top-background: $site-default !default;
$date-box-top-text: $white !default;
$social-share-background: $darkgrey !default;
$social-share-background-hover: $site-default !default;
$icon-header: $darkgrey !default;

// CTA
$cta-background: $site-default !default;
$cta-background-hover: darken($cta-background, 7%) !default;
$cta-color: $white !default;
$primary-pusher-cta-background: $cta-background !default;
$primary-pusher-cta-background-hover: $cta-background-hover !default;
$primary-pusher-cta-color: $cta-color !default;
$more-link-background: $cta-background !default;
$more-link-color: $cta-color !default;
$resource-link-background: $cta-background !default;
$resource-link-background-hover: $cta-background-hover !default;
$resource-link-color: $cta-color !default;
$mailchimp-cta-background: $cta-background !default;
$mailchimp-cta-background-hover: $cta-background-hover !default;
$mailchimp-cta-color: $cta-color !default;

$visualarena-active-menu-item: rgba(255, 255, 255, .3) !default;
$visualarena-mobile-menu-bg: rgba(0, 0, 0, .4) !default;

// Swiper
$swiper-pagination-active: $site-default !default;

// Filter
$filter-color: $site-default !default;
$filter-border-radius: 2px !default;
$filter-transition-function: cubic-bezier(.165, .84, .44, 1) !default;

// Card specific, migrated from lindholmen_v2_lindholmen theme
$box-shadow: 0 10px 38px rgba(0, 26, 100, .05) !default;
$cubic-bezier: cubic-bezier(.165, .84, .44, 1) !default;

// Border radius
$border-radius: 2px !default;

// Name the breakpoints in a way that creates a ubiquitous language
// across team members. It will improve communication between
// stakeholders, designers, developers, and testers. Also note that
// since we develop in a 'mobile-first'-fashion, the 'mobile' breakpoint
// is to be considered the default and hence not included here.
$breakpoints: (
  "phablet-mini": 400px,
  "phablet": 416px,
  "tablet": 767px,
  "laptop": 1024px,
  "desktop": 1200px,
  "large-desktop": 1600px,

  // Tweakpoints
  "swd-logo-1": 960px,
  "swd-logo-2": 1400px,
  "pusher-switch-1": 560px,
  "pusher-switch--count-2": 700px,
  "pusher-switch-2": 980px,
  "pusher-switch-3": 1230px,
);

// Tweakpoints map initiation. Don't populate this here; instead create a
// new $tweakpoints-map per .scss-file if needed. See the __header.scss-file
// for an working example.
$tweakpoints: ();

// This variable is used in the old-ie.scss to render a static
// stylesheet devoid of mediaqueries for use in IE8 and below.
$responsive: true !default;

// Define the breakpoint from the $breakpoints list that should
// be used as the target width when outputting a static stylesheet
// (i.e. when $responsive is set to 'false').
$static-breakpoint: "laptop";


// General layout variables.
$wrapper-max-width: 1200px!default;
$footer-height: 275px !default;
$footer--tablet-height: 296px !default;


// font-face declarations
