$tweakpoints: (
  "3-col": 568px,
  "2-col": 900px,
);

.section {
  background: #f7f7f7;
  border: 1px solid #e4e4e4;
  clear: both;
  float: left;
  margin-bottom: 1.611111111em;
  padding: 0 20px;
  width: 100%;
}

.section__title {
  color: $section-header;
  font-weight: normal;
  margin-bottom: 30px;
  margin-top: 22px;
  text-align: center;
  text-transform: uppercase;
}

.section--view {   // Q: dafuq? a modifier-selector?
  float: left;      // A: Yes. 'cause the Drupal class-sanitizer (that views uses) is whack.
  width: 100%;      // See https://www.drupal.org/node/2427925 for more info.
}

.partners__list-item {
  padding: 2em 0;
}

.partners__list-item--sidebar {
  float: left;
  padding: 1em 0;
  width: 45.508982%;

  &.odd {
    margin-right: 8.9820359%;
  }

  @include breakpoint("3-col") {

    &.odd {
      margin-right: 0;
    }

    &.pl-1 {
      clear: left;
      float: left;
      margin-right: -100%;
      width: 29.8122%;
    }

    &.pl-2 {
      float: left;
      margin-left: 35.0939%;
      margin-right: -100%;
      width: 29.8122%;
    }

    &.pl-3 {
      float: left;
      margin-left: 70.1878%;
      margin-right: -100%;
      width: 29.8122%;
    }

  }

  @include breakpoint("2-col") {
    &.pl-1,
    &.pl-2,
    &.pl-3 {
      clear: none;
      margin-left: 0;
      width: 45.508982%;
    }

    &.odd {
      margin-right: 8.9820359%;
    }

  }

}




.partners__logo {
  display: block;
  width: auto;
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
