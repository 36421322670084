blockquote {
  line-height: 1.6;
  margin: 4rem 0 3rem;
  position: relative;

  &,
  p,
  .content__body &,
  .content__body & p {
    font-size: 1.3rem;
    font-family: "apercu-italic";
    line-height: 1.6;

    @include breakpoint("tablet") {
      font-size: 1.6rem;
    }
  }

  &::before {
    background-image: url('../../images/quote-icon.svg');
    background-repeat: no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 8rem;
    margin: -2rem 0 0 -1.2rem;
    position: absolute;
    width: 7rem;

    @include breakpoint("tablet") {
      margin: -3rem 0 0 -4rem;
      height: 9rem;
      width: 9rem;
    }
  }
}
