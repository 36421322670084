// .forum-table-topics td.views-field-title {
//   @include font-size(12px, 1.3);

//   a {
//     @include font-size(14px, 1.3);
//   }
// }

.forum-table-topics td.views-field-topic-icon {
  padding-right: 0;
}

// .forum-table .views-field-comment-count {
//   @include font-size(14px, 1.3);
// }

// .forum-table .views-field-last-updated {
//   @include font-size(14px, 1.3);
// }
