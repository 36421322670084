$tweakpoints: (
  "content__2-col": 580px,
);

.referenced__list-title {
  @include font-size(24px, 1.2);

  margin-bottom: 20px;

  @include breakpoint("content__2-col") {
    @include font-size(30px, 1.2);
  }

}

.referenced-content--view {     // Q: dafuq? a modifier-selector?
  float: left;                  // A: Yes. 'cause the Drupal class-sanitizer (that views uses) is whack.
  margin-bottom: 20px;          // See https://www.drupal.org/node/2427925 for more info.
}

.referenced-content__list-item {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 30px;
  padding-bottom: 10px;
}

.referenced-content__list-item--odd {
  margin-right: -100%;
  width: 100%;

  @include breakpoint("content__2-col") {
    clear: left;
    float: left;
    width: 47.7791878%;
  }
}

.referenced-content__list-item--even {
  margin-right: -100%;
  width: 100%;

  @include breakpoint("content__2-col") {
    float: left;
    margin-left: 52.2208122%;
    width: 47.7791878%;
  }
}

.referenced-content__list-item--first {
  clear: none;
}


.referenced-content__wrapper .referenced-content__link {
  display: block;

  &:hover {
    text-decoration: none;

    .referenced-content__title {
      text-decoration: underline;
    }

  }

}

.referenced-content__title {
  @include font-size(22px, 1.2);

  margin-bottom: 8px;

  @include breakpoint("content__2-col") {
    @include font-size(20px, 1.2);
  }

}

.referenced-content__preamble {
  color: $blackgrey;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
