.secondary-navigation {
  border-bottom: 1px solid $border-color;
  float: left;
  margin-bottom: 20px;
  padding-bottom: 30px;
  width: 100%;

  .block__title {

    a {
      @include font-size(24px, 1.3);

      background: transparent;
      border-bottom: 1px solid $border-color;
      color: $black;
      display: block;
      font-weight: bold;
      margin-bottom: 10px;
      padding: 10px 13px 9px;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  // 1st level
  .leaf,
  .collapsed,
  .expanded {

    a {
      @include font-size(20px, 1.3);

      color: $link-primary;
      display: block;
      padding: 10px 13px;
      text-decoration: none;

      &:hover {
        background: $site-default;
        color: $white;
      }

      &.active {
        background: $site-default;
        font-weight: 700;
      }

      &.active-trail {
        background: $site-default;
        color: $white;

        &:hover {
          background: $site-default;
          color: $white;
        }
      }

    }

    // 2nd level
    .leaf {
      background: #e9f7f7;
      padding-bottom: 10px;
    }

    .leaf,
    .collapsed,
    .expanded {
      padding-bottom: 0;

      a {
        @include font-size(18px, 1.3);

        padding: 10px 26px;

        &:hover {
          background: $site-default;
          color: $white;
        }

        &.active {
          background: transparent;
          color: $link-primary;
          font-weight: 700;
        }

        &.active::before {
          @include font-size(16px, 1);

          content: "\e60d";
          font-family: "Lindholmen icons";
          -webkit-font-smoothing: antialiased;
          font-style: normal;
          font-weight: normal;
          left: 0;
          padding-right: 8px;
          position: relative;
          text-transform: none;
          top: 1px;
        }
      }

    }

  }

}
