$tweakpoints: (
  "content__2-col": 580px,
  "mobile__2-col": 568px,
);

.resources__main-wrapper {
  margin-top: 40px;
  overflow: auto;
  clear: left;
  &.resources__main-wrapper--contacts {
    overflow: initial;
  }
}

.resources__main-title {
  @include font-size(24px, 1.2);

  float: left;
  margin-bottom: 30px;
  width: 100%;

  @include breakpoint("content__2-col") {
    @include font-size(30px, 1.2);
  }

}

.resources__preamble {
  float: left;
  width: 100%;
}

.resources--view {              // Q: dafuq? a modifier-selector?
  float: left;                  // A: Yes. 'cause the Drupal class-sanitizer (that views uses) is whack.
  margin-bottom: 20px;          // See https://www.drupal.org/node/2427925 for more info.
  width: 100%;
}

.resource__list-item {
  border-bottom: 1px solid $border-color;
  margin: 20px 0;
  padding: 20px 0;

  &.last {
    border-bottom: 0;
  }
}

.resources__group {
  border-top: 1px solid $border-color;

  &:last-child {
    border-bottom: 1px solid $border-color;
  }
}

.resources__group-title {
  @include font-size(20px, 1.2);

  color: $link-primary;
  padding: 20px 0 20px 10px;
  position: relative;
  user-select: none;

  &::after {
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid $link-primary;
    content: "";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    text-decoration: underline;
  }
}

.resources__group-title--open {
  &::after {
    border-bottom: 10px solid $link-primary;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 0;
  }
}

.resources__list {
  margin: 0 10px;

  .js .resources__group & {
    display: none;
  }

}

.resources__contacts {
  display: flex;
  flex-flow: row wrap;
  clear: left;
  .node-contact-person {
    padding-right: 20px;
    margin-bottom: 40px;
    width: 100%;
    max-width: 260px;
  }
}

.resource__item {
  margin-bottom: 30px;

  .resources__list-item:last-child & {
    margin-bottom: 10px;
  }
}

.resource__item--author {
  box-shadow: 0 2px 36px 0 rgba(0, 0, 0, .08);
  margin-bottom: 5.1764706%;

  @include breakpoint("mobile__2-col") {
    margin-bottom: 12.7478754%;
  }

  .main-content & a:hover {
    text-decoration: none;
  }
}

.resource__title {
  @include font-size(20px, 1.2);
}

.resource__title--author {
  @include font-size(24px, 1.2);

  color: $text-body;
  font-weight: bold;
  margin-bottom: 15px;
}

.resource__link-separator,
.resource__external-link {
  @include font-size(16px, 1.2);
}

.resource__link-separator {
  color: $text-preamble;
  font-weight: 400;
}

.resource__external-link {
  font-weight: 400;
  text-overflow: ellipsis;
}

.resource__phone-number {
  @include font-size(16px, 1.2);

  display: block;
  margin-bottom: 4px;

  &::before {
    @include font-size(13px, 1.2);

    content: "\e6111";
    float: left;
    font-family: "Lindholmen icons";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    margin-right: 8px;
    position: relative;
    text-transform: none;
    top: 2px;
  }
}

.resource__address {
  @include font-size(16px, 1.2);

  color: $meta-info;
  float: left;
  margin: 10px 0;

  &::before {
    content: "\e608";
    display: block;
    float: left;
    font-family: "Lindholmen icons";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    margin-right: 8px;
    position: relative;
    text-transform: none;
  }

  p {
    float: left;
    margin-bottom: 0;
  }
}

.resource__image-wrapper {
  float: left;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding-right: 0;
  width: 100%;

  @include breakpoint(phablet) {
    padding-right: 2rem;
    width: 30%;
  }
}

.resource__image-wrapper--author {
  float: none;
  margin: 0;
  width: 100%;
}

.resource__image-link {
  display: block;
  text-align: center;

  &:hover .resource__image-overlay-wrapper,
  &:hover .resource__image-overlay {
    display: block;
  }
}

.resource__image-overlay-wrapper {
  background: $link-primary;
  bottom: 0;
  left: 0;
  opacity: .7;
  position: absolute;
  right: 0;
  top: 0;

  .js & {
    display: none;
  }
}

.resource__image-overlay {
  color: $white;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);

  .js & {
    display: none;
  }
}

.resource__image {
  display: block;
}


.resource__data-wrapper {
  border-bottom: 1px dotted $resource-separator;
  float: left;
  padding-bottom: 40px;
  margin-left: 0;
  margin-right: 0;
  margin-top: 1rem;
  width: 100%;

  @include breakpoint(phablet) {
    margin-top: 0;
    width: 70%;
  }

  .spamspan {
    @include font-size(16px, 1.2);

    clear: both;
    display: block;
    margin: 10px 0 4px;

    &::before {
      @include font-size(13px, 1.2);

      content: "\e612";
      float: left;
      font-family: "Lindholmen icons";
      -webkit-font-smoothing: antialiased;
      font-style: normal;
      font-weight: normal;
      margin-right: 6px;
      position: relative;
      text-transform: none;
      top: 3px;
    }

  }

  .resource__link--button {
    background: $resource-link-background;
    color: $resource-link-color;
    display: inline-block;
    margin-right: 5px;
    padding: 6px 19px;

    &:hover {
      background: $resource-link-background-hover;
      text-decoration: none;
    }
  }

  .resources__list-item:last-child & {
    border-bottom: 0;
  }

}

.resource__data-wrapper--author {
  border: 0;
  float: none;
  margin: 0;
  padding: 35px;
  width: 100%;
}

.resource__description {
  @include font-size(16px, 1.3);

  color: $meta-info;

  p {
    margin-bottom: 0;
  }
}

.resource__description--author {
  @include font-size(16px, 1.88);

  color: $text-body;
}

.resource__description--gallery-image {
  color: $text-body;
}

.resource__image-credit {
  @include font-size(16px, 1.3);

  background: url("../../images/resource-camera.png") no-repeat 0 50%;
  background-size: 14px 10px;
  color: $meta-info;
  margin-top: 6px;
  padding-left: 18px;
}

.resource__publish-date {
  @include font-size(16px, 1.3);
  color: $meta-info;
}

.resource__file-meta {
  @include font-size(14px, 1.3);
  color: $meta-info;
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
