.events-archive__header {
  @include font-size(24px, 1.3);

  border-bottom: 1px solid $border-color;
  color: $black;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  padding: 10px 13px 9px;
  text-decoration: none;
}

.sidebar__section--events-archive {

  li {
    @include font-size(16px, 1.2);

    color: $meta-info;

    a {
      @include font-size(20px, 1.2);

      color: $link-primary;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

}

.event-archive__grouping-header {
  @include font-size(18px, 1.2);

  margin-top: 10px;
  text-transform: capitalize;

  @include breakpoint("phablet") {
    @include font-size(20px, 1.2);
  }
}

.event-archive__list {
  margin-bottom: 20px;
}

.event-archive__list-item {
  border-bottom: 1px solid $border-color;
  margin-left: 10px;
  padding: 10px 0;
}

.event-archive__list-item:last-child {
  border-bottom: 0;
}

.event-archive__title {
  @include font-size(18px, 1.2);

  font-weight: 400;

  @include breakpoint("phablet") {
    @include font-size(22px, 1.2);
  }
}

.event-archive__date {
  @include font-size(14px, 1.2);

  color: $meta-info;
}
