// Styles for generic content, that looks consistent across content-types, for
// example WYSIWYG-styles and content-containers. Please take the time to make
// sure that font-sizes look ok on all screen widths that are supported.

$tweakpoints: (
  "sidebar-switch": 900px,
);

.content__wrap--with-sidebar {
  float: left;
  margin-right: -100%;
  width: 100%;

  @include breakpoint("sidebar-switch") {
    width: 65.6576%;
  }
}

.content__sidebar {
  clear: left;
  float: left;
  margin-right: -100%;
  width: 100%;

  @include breakpoint("sidebar-switch") {
    clear: none;
    margin-left: 68.5872%;
    width: 31.3639%;
  }
}

.content__title {
  @include font-size(24px, 1.2);

  color: $title-large-color;
  font-weight: 300;
  margin-bottom: 5px;

  @include breakpoint("tablet") {
    @include font-size(48px, 1.2);
  }
}

.content__subtitle--h2 {
  @include font-size(22px, 1.3);

  color: $title-color;
  font-weight: 700;
  margin-bottom: .5rem;

  @include breakpoint("tablet") {
    @include font-size(25px, 1.3);
  }
}

.content__subtitle--h3 {
  @include font-size(20px, 1.3);

  color: $title-color;
  margin-bottom: .5rem;
}

.content__subtitle--h4 {
  @include font-size(16px, 1.3);

  color: #667177;
  margin-bottom: .5rem;
  text-transform: uppercase;
}

.content__code {
  background: #fafafa;
  border: 1px solid #f0f2f3;
  border-radius: 3px;
  color: #292e31;
  font-weight: 600;
  padding: 2px 4px;
}

.content__cta {
  background: #fafafa;
  border: 1px solid #e8eaeb;
  border-radius: 5px;
  color: #6f7c82;
  margin: 35px 0 15px;
  padding: 20px;
  text-align: center;
}

.content__back-link {
  @include font-size(16px, 1.3);
  font-family: "apercu-regular", Helvetica, sans-serif;

  @include breakpoint("phablet") {
    @include font-size(18px, 1.3);
  }

  a {
    color: $meta-info;
    font-weight: 100;
  }
}

.content__preamble {
  &,
  p,
  .content__body &,
  .content__body & p {
    @include intro;
    color: $text-preamble;
    margin-bottom: 2rem;
  }
}

.content__body {

  iframe {
    max-width: 100%;
  }

  h2 {
    @include content-h2;
  }

  h3 {
    @include content-h3;
  }

  h4 {
    @include content-h4;
  }

  h5 {
    @include content-h5;
  }

  p {
    font-size: 1.1rem;
    line-height: 1.5;

    @include breakpoint("tablet") {
      font-size: 1.3rem;
      line-height: 1.6;
    }
  }

  strong,
  b {
    font-family: "apercu-bold";
    font-weight: normal;

    em,
    i {
      font-family: "apercu-bold-italic";
      font-weight: normal;
    }
  }

  em,
  i {
    font-family: "apercu-italic";
    font-weight: normal;

    strong,
    b {
      font-family: "apercu-bold-italic";
      font-weight: normal;
    }
  }

  // a {
  //   color: $cyan--darker;
  // }

  ul,
  ol {
    margin: 0 0 1.5rem;
  }

  ul {
    list-style: none!important;
    padding: 0;

    li {
      background: transparent;
      font-family: "apercu-regular";
      list-style: none;
      margin: 0 0 .3rem;
      padding-left: 1.5em;
      position: relative;

      p {
        margin: 0;
      }

      &::before {
        color: $site-default;
        content: "•";
        font-size: 3rem;
        left: 0;
        line-height: .7em;
        position: absolute;
        top: 0;
      }
    }
  }

  ol {
    li {
      font-family: "apercu-regular";
      list-style: decimal;
      margin: 0 0 .3rem 1.3em;
      padding-left: .2em;

      p {
        margin: 0;
      }
    }
  }

  .cta {
    border-radius: $border-radius;
  }

  .right-arrow {
    font-family: "apercu-medium";
  }
}

.content__attached-files {
  ul {
    list-style: none !important;
  }
}

.content__body--frontpage {
  margin-bottom: 70px;
}

.content__image--right {
  float: right;
  margin: 0 0 10px 10px;
}

.content__image--left {
  float: left;
  margin: 0 10px 10px 0;
}

.content__figure {
  margin-bottom: 16px;
}

.content__caption {
  font-size: .9rem;
  line-height: 1.5;

  @include breakpoint("tablet") {
    font-size: 1rem;
  }
}

.main-content {

  a {
    color: $link-body;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

// Empty the $tweakpoints list at EOF to avoid variable-leaks between compontents.
$tweakpoints: ();
