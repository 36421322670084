$card-margin: 2rem;
$card-date-size: 4.5rem;

.card {
  background: $white;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  display: block;
  float: left;
  margin-bottom: 2rem;
  margin-left: calc($card-margin / 2);
  margin-right: calc($card-margin / 2);
  overflow: hidden;
  position: relative;
  text-decoration: none;

  @include breakpoint("tablet") {
    margin-bottom: 3rem;
  }

  @include breakpoint("desktop") {
    margin-bottom: 4rem;
  }

  .l-section--darkblue & {
    background-color: $darkblue;
  }

  &__img {
    position: relative;
    transition: opacity .5s $cubic-bezier;

    img {
      display: block;
      width: 100%;
    }

    .program-portfolio &,
    .view-display-id-graphs & {
      &::after {
        background: linear-gradient(to bottom, transparent 0%, rgba($black, .85) 100%);
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }

  &__img-placeholder {
      background: $darkblue;
      padding-top: 66.67%;
  }

  &__date {
    background: darken($turquoise, 5%);
    border-radius: $border-radius;
    color: $white;
    font-family: "apercu-medium";
    font-size: 1rem;
    height: $card-date-size;
    left: 1.5rem;
    line-height: calc($card-date-size / 2);
    overflow: hidden;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 1.5rem;
    width: $card-date-size;

    @include breakpoint("desktop") {
      left: 2rem;
      top: 2rem;
    }

    span {
      display: block;
      height: calc($card-date-size / 2);
      width: 100%;

      &:nth-child(1) {
        background: rgba($black, .12);
      }
    }
  }

  .date-all-day-label {
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 160px;
    padding: 1.5rem;

    .l-section--darkblue & {
      background-color: $white;
    }

    @include breakpoint("tablet") {
      min-height: 192px;
    }

    @include breakpoint("desktop") {
      min-height: 224px;

      .card-list--2-col & {
        min-height: 375px;
      }
    }

    @include breakpoint("large-desktop") {
      min-height: 256px;
      padding: 2rem;

      .card-list--2-col & {
        min-height: 448px;
      }
    }

    .program-portfolio &,
    .view-display-id-graphs & {
      bottom: 0;
      height: 100%;
      left: 0;
      min-height: 0;
      position: absolute;
      width: 100%;
    }

    .icon-before {
      line-height: 1.5;
      padding-left: 25px;
      position: relative;

      @include breakpoint("tablet") {
        font-size: 1.2rem;
        line-height: 1.6;
        padding-left: 30px;
      }

      &::before {
        left: 0;
        line-height: 1.2;
        position: absolute;

        @include breakpoint("tablet") {
          line-height: 1.3;
        }
      }
    }

    .icon-before--pin {
      &::before {
        left: 1px;

        @include breakpoint("tablet") {
          left: 2px;
        }
      }
    }
  }

  &__heading {

    .program-portfolio &,
    .view-display-id-graphs & {
      bottom: 0;
      color: $white;
      left: 0;
      line-height: 1.2;
      padding: 2rem;
      position: absolute;
      width: 100%;
    }
  }

  &__title {
    font-family: "apercu-medium";
    font-size: 1.4rem;
    line-height: 1.2;
    margin: 0 0 .33rem;

    .program-portfolio &,
    .view-display-id-graphs & {
      color: $white;
    }

    @include breakpoint("tablet") {
      .card-list--2-col & {
        font-size: 1.75rem;
      }
    }
  }

  &__sub-title {
    margin: 0;
    font-size: 1rem;

    &::before {
      font-size: .9rem;
    }

    .node-program-pusher & {
      margin-bottom: .33rem;
    }

    .node-event & {
      line-height: 1.5;
    }

    &--larger {
      font-size: 1.15rem;
    }
  }

  &__meta {
      margin: 0 0 1rem;
  }

  &__stamp {
    background: $cyan;
    border-radius: 4px;
    display: inline-block;
    font-family: "apercu-medium";
    font-size: .7rem;
    line-height: 1;
    margin-right: .075rem;
    padding: 0.25rem .3rem .2rem;
    position: relative;
    text-transform: uppercase;
    top: -.1rem;
  }

  &__teaser {
      font-family: "tiempos-regular";
  }

  &__bottom {

    .program-portfolio &,
    .view-display-id-graphs & {
      display: none;
    }

    a {
      display: block;

      .node-contact-person & {
        font-size: 1.1rem;
      }
    }
  }

  &__read-more {
    font-family: "apercu-medium";


    &--external {
      &::after {
        background-image: url("../../images/external.svg");
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        display: inline-block;
        height: 1rem;
        margin-left: .5rem;
        position: relative;
        top: .05em;
        width: 1rem;
      }
    }
  }

  .js-animate & {
    transform: translate3d(0, -1rem, 0);
    transition-duration: 3s;
    transition-property: opacity, transform;
    transition-timing-function: $cubic-bezier;
  }

  .is-visible & {
    opacity: 1 !important;
    transform: translate3d(0, 0, 0);
  }

  &:hover {
    text-decoration: none!important;

    .card__img {
      opacity: .75;
    }
  }
}

// No hover on contact person card
.card {
  &.contacts__person {
    &:hover {
      .card__img {
        opacity: 1;
      }
    }
  }
}

// Card list layout and animation
.card-list {

  .node-type-portfolio-type &,
  .view-display-id-graphs & {
    margin-top: 5rem;
  }

  &::after {
    content: "";
    display: block;
  }

  @include breakpoint("tablet") {

    &.card-list--2-col .card,
    &.card-list--3-col .card,
    &.card-list--4-col .card {
      width: calc(50% - #{$card-margin});
    }
  }

  @include breakpoint("desktop") {

    &.card-list--2-col .card {
      width: calc(50% - #{$card-margin});
    }

    &.card-list--3-col .card {
      width: calc(33.3333% - #{$card-margin});
    }

    &.card-list--4-col .card {
      width: calc(25% - #{$card-margin});
    }
  }

  .view-content {
    margin-left: -1rem;
    margin-right: -1rem;
    width: calc(100% + 2rem);

    div {

      // Transition delays
      &:nth-child(1) .card {
        transition-delay: .15s;
      }

      &:nth-child(2) .card {
        transition-delay: .3s;
      }

      &:nth-child(3) .card {
        transition-delay: .45s;
      }

      &:nth-child(4) .card {
        transition-delay: .6s;
      }

      &:nth-child(5) .card {
        transition-delay: .75s;
      }

      &:nth-child(6) .card {
        transition-delay: .9s;
      }

      &:nth-child(7) .card {
        transition-delay: 1.15s;
      }

      &:nth-child(8) .card {
        transition-delay: 1.3s;
      }

    }
  }
}


.card-list {

  // 2 col
  &.card-list--2-col {
    margin: 0 auto;
    max-width: 1200px;

    .view-content {
      ul {
         li {
             @include breakpoint("tablet") {
               &:nth-child(2n+1) {
                 clear: left;
               }
             }
         }
      }
    }
  }

  // 3 col
  &.card-list--3-col {
    .view-content {
      div {

        @include breakpoint("tablet") {
          &:nth-child(2n+1) {
            clear: left;
          }
        }

        @include breakpoint("desktop") {
          clear: none!important;

          &:nth-child(3n+1) {
            clear: left!important;
          }
        }
      }
    }
  }

  // 4 col
  &.card-list--4-col {
    .view-content {
      div {

        @include breakpoint("tablet") {
          &:nth-child(2n+1) {
            clear: left;
          }
        }

        @include breakpoint("desktop") {
          clear: none!important;

          &:nth-child(4n+1) {
            clear: left!important;
          }
        }
      }
    }
  }

}
