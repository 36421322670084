$tweakpoints: (
  "sidebar-switch": 900px,
);

.form-text,
.form-file {
  border: 1px solid $border-color;
  color: $text-body;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 14px;
  width: 100%;

  @include breakpoint("tablet") {
    width: auto;
  }

}

.form-submit {
  -webkit-appearance: none;
  background: $link-primary;
  border: 0;
  box-sizing: border-box;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin-right: 10px;
  padding: 12px 30px;
  vertical-align: middle;

  &:hover {
    background: #008c8d;
  }

}

.form-item .description {
  color: $meta-info;
}

.cke {
  max-width: none;
}

.webform-client-form {

  label {
    font-family: "apercu-bold", "helvetica", sans-serif;
  }

  .webform-component {
    float: left;
    width: 100%;

    .form-item {
      .description {
        font-size: 75%;
      }
    }
  }

  .webform-component-select {

    select {
      -webkit-appearance: none;
      background: transparent;
      border-radius: $border-radius;
      border: 1px solid $border-color;
      font-size: .9rem;
      height: 2rem;
      max-width: 100%;
      padding: 0 .5rem;

      @include breakpoint("tablet") {
        height: 3rem;
        padding: 0 1rem;
      }
    }
  }

  .webform-component-textfield,
  .webform-component-email {
    margin: 0 0 1rem;

    .form-text {
      max-width: 100%;
      width: 100%;

      &:not([size='60']) {
        width: auto;
      }
    }

    @include breakpoint("tablet") {
      float: left;
      margin: 0 5% 1rem 0;
      width: 45%;

      &+.webform-component-textfield,
      &+.webform-component-email {
        margin: 0 0 1rem 5%;

        // Not the prettiest solution, could possiblty be cleaned up.
        // See KM-3475 point 3 for more information.
        &+.webform-component-textfield,
        &+.webform-component-email {
          margin: 0 5% 1rem 0;

          &+.webform-component-textfield,
          &+.webform-component-email {
            margin: 0 0 1rem 5%;

            &+.webform-component-textfield,
            &+.webform-component-email {
              margin: 0 5% 1rem 0;

              &+.webform-component-textfield,
              &+.webform-component-email {
                margin: 0 0 1rem 5%;

                &+.webform-component-textfield,
                &+.webform-component-email {
                  margin: 0 5% 1rem 0;

                  &+.webform-component-textfield,
                  &+.webform-component-email {
                    margin: 0 0 1rem 5%;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .webform-component-file {
    border-bottom: 1px solid $border-color;
    margin: 0 0 2rem;
    padding-bottom: 2rem;

    label {
      margin-bottom: 1rem;
    }

    .form-managed-file {
      font-size: 80%;
    }
  }

  .webform-component-multifile {
    margin: 0;

    .form-type-file {
      margin: 0;

      label {
        font-size: 1.2rem;
      }

      .MultiFile-wrap {
        font-size: 80%;
      }
    }
  }

  .webform-component-markup {
    font-size: 80%;
    margin: 0;

    h2 {
      margin: 1.5rem 0 2rem;
    }

    h3 {
      font-size: 1.2rem;
      margin-left: 0;
    }

    p {
      margin: 0 0 1rem;
    }

    .accent {
      color: #f00;
    }
  }

  .form-radios,
  .form-checkboxes {
    font-size: 80%;
  }

  .form-actions {
    float: left;
    margin-bottom: 4rem;
    width: 100%;

    @include breakpoint("sidebar-switch") {
      margin-bottom: 0;
    }
  }

  .form-submit {
    -webkit-appearance: none;
    background: $secondary-pusher-background;
    margin-top: .5rem;

    &:hover {
      background: $link-primary;
    }

    @include breakpoint("tablet") {
      margin-top: 0;
    }
  }
}
