$tablehead: #eeeeee;

$text: #000;
$silver: #ccc;

$odd: #f9f9f9;
$even: #fff;

$padding: 16px;
$margin: 16px;


// BUTTONS

// Reset
.forum-topic-header .last-post-link {
  margin: 0;
}

.forum-topic-header,
.forum-node-create-links {
  .button {
    display: inline-block;
    margin-right: calc($margin / 2);
  }
}

.button + .button,
.last-post-link a {
  margin-right: calc($margin / 2);
}


.af-button-large, .af-button-small, .author-pane-link {
  font-family: inherit;
}



#advanced-forum-forum-topic-list-sort-form#advanced-forum-forum-topic-list-sort-form input {
  padding: 5px 10px !important;
}


.forum-post-links {
  line-height: 1.3;
  margin: 0;
  text-align: left;

  ul.links {
    @extend %clearfix;

    margin: 0;

    li {
      background: none;
      float: right;
      padding-left: 0;
    }
  }
}

.comment-add,
.comment-reply,
.comment-edit,
.comment-delete,
.reportabuse,
.post-edit,
.post-delete {
  a.af-button-small,
  .af-button-small,
  a {
    background: #eee;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    display: block;
    line-height: normal;
    margin-left: 1px;
    margin-top: 1px;
    padding: 4px 12px;
    text-shadow: none;

    span {
      @include font-size(12px, 1.4);

      background: none;
      color: $text;
      display: block;
      line-height: normal;
      margin: 0;
      padding: 0;
      text-shadow: none;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    &:hover {
      background: $silver;

      span {
        color: $text;
      }
    }
  }

  .forum-post-links & a {
    //padding: calc($padding / 4) calc($padding / 2);

    &:first-letter {
      //text-transform: uppercase;
    }
  }
}

.flag-forum-report-node,
.flag-forum-report-comment {
  @extend %clearfix;

  background: #eee;
  display: block;
  margin-top: 1px;
  padding: 4px 12px;

  &.flag-waiting {
    padding-right: 23px;
  }

  .flag {
    @include font-size(12px, 1.4);

    display: block;
    float: left;
  }

  .flag-action,
  .unflag-action {
    color: $text;
  }

  .flag-throbber {
    float: left;
    position: absolute;
    top: 1px;
  }

  &:hover {
    background: darken(#e0dfdf, 3%);
  }
}

.forum-table-wrap {
  margin-bottom: $margin;

  @include breakpoint("tablet") {
    margin-bottom: $margin*2;
  }
}


// POST OR LOGIN AREA //

.forum-topic-header .topic-reply-link,
.forum-topic-header .topic-new-post-link,
.forum-topic-header .topic-post-count {
  margin: 0;
  padding: 0;
}

#forum-topic-header .topic-reply-link {
  float: right;

  a {
    display: block;
    margin-bottom: 8px;
  }

}

#forum-topic-header,
.forum-node-create-links {
  margin: 0px 0px $margin;
  padding: 0;
  width: 100%;

  .button {
    margin-bottom: calc($margin / 2);
  }

  @include breakpoint("tablet") {
    .button {
      margin-bottom: 0;
    }
  }
}

#forum-topic-header {
  margin-bottom: 0;
}

.page-forum,
.node-type-forum {

  .l-content {
    padding: 0;

    @include breakpoint("tablet") {
      padding-bottom: 40px;
    }

  }

  .page-title {
    @include font-size(32px, 1.3);
  }

  .pane-forum-topic-list {
    margin: 0;
  }

}

// FORUM HEADER

.forum-table-wrap .forum-table-superheader,
.forum-table-topics thead tr {
  background: $tablehead;
  border-bottom: none;
}

.forum-header th, .forum-table thead tr th {
  padding: calc($padding / 2);
  @include font-size(12px, 1.3);

  @include breakpoint("tablet") {
    padding: calc($padding / 2) $padding;
    @include font-size(14px, 1.3);
  }
}




// FORUM BODY
.forum-table {
  display: table;
}
.forum-table tbody {
  display: table-row-group;
}

.forum-table tbody td {
  border: none;
  padding: calc($padding / 2);
  vertical-align: middle;

  @include breakpoint("tablet") {
    padding: calc($padding / 2) $padding;
  }
}

.forum-table tbody tr.forum-row {
  padding: 0;
}

.forum-table tbody .odd {
  background: $odd;
}
.forum-table tbody .even {
  background: $even;
}

.form-textarea {
  border: 1px solid #ebebeb;
}



// FORUM TOOLS

#forum-sort {
  float: none;
  margin: 20px 0;

  @include breakpoint("tablet") {
    float: left;
  }
}

#forum-pager-top .pager,
#forum-pager-bottom .pager {
  padding: 0;
}

.forum-pager .pager {
  margin: 10px 0;
  width: 100%;

  @include breakpoint("tablet") {
    width: 70%;
  }
}

.forum-tools {
  float: none;
  margin: 10px 0;

  #edit-jump {
    width: 100%;
  }

  @include breakpoint("tablet") {
    float: left;
  }
}

.forum__flagged-title {
  padding: calc($padding / 2) $padding;
  margin: 0;
}

// FORUM PAGER

#forum-comments .pager li {
  padding: 0;
}

.forum-pager .pager,
#forum-comments .pager {

  a,
  .pager-current {
    padding: 1px 4px;
  }

  li {
    @include font-size(14px, 1.3);
  }

}

// RESPONSIVE

// Visibility

th, td {

  &.views-field-last-updated,
  &.forum-last-post,
  &.forum-last-reply,
  &.forum-icon,
  &.forum-list-icon,
  &.views-field-topic-icon {

    display: none;

    @include breakpoint("tablet") {
      display: table-cell;
    }
  }
}


.forum__date {
  color: #515151;
  @include font-size(12px, 1.3);
}

.forum__notification-badge {
  background: #ababab;
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  margin-left: 4px;
  padding: 3px 4px;
}

.forum-table-topics td.views-field-title .forum__user--list {

  a {
    @include font-size(12px, 1.3);
  }

}

.forum--reported-content {
  display: block;
  margin-bottom: 30px;
}
