/*
 * Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2017 Colophon Foundry
 *
 * Licenced to Andr&eacute; Aznar Kodamera
 */


 /* * * * * * * * * * * * * * * * * * * *
 *  Apercu font
 * * * * * * * * * * * * * * * * * * * */

 @font-face {
     font-family: "apercu-bold-italic";
     src: url("../../fonts/apercu/apercu-bold-italic.eot");
     src: url("../../fonts/apercu/apercu-bold-italic.eot?#iefix") format("embedded-opentype"),
          url("../../fonts/apercu/apercu-bold-italic.woff") format("woff"),
          url("../../fonts/apercu/apercu-bold-italic.woff2") format("woff2"),
          url("../../fonts/apercu/apercu-bold-italic.ttf") format("truetype");
     font-style: normal;
     font-weight: normal;
 }

@font-face {
    font-family: "apercu-italic";
    src: url("../../fonts/apercu/apercu-italic.eot");
    src: url("../../fonts/apercu/apercu-italic.eot?#iefix") format("embedded-opentype"),
         url("../../fonts/apercu/apercu-italic.woff") format("woff"),
         url("../../fonts/apercu/apercu-italic.woff2") format("woff2"),
         url("../../fonts/apercu/apercu-italic.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: 'Apercu';
    src: url('Apercu-Medium.woff2') format('woff2'),
        url('Apercu-Medium.woff') format('woff'),
        url('Apercu-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'apercu-medium';
    src: url('../../fonts/apercu/Apercu-Medium.eot');
    src: url('../../fonts/apercu/Apercu-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/apercu/Apercu-Medium.woff2') format('woff2'),
        url('../../fonts/apercu/Apercu-Medium.woff') format('woff'),
        url('../../fonts/apercu/Apercu-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}


@font-face {
    font-family: "apercu-regular";
    src: url("../../fonts/apercu/apercu-regular.eot");
    src: url("../../fonts/apercu/apercu-regular.eot?#iefix") format("embedded-opentype"),
         url("../../fonts/apercu/apercu-regular.woff") format("woff"),
         url("../../fonts/apercu/apercu-regular.woff2") format("woff2"),
         url("../../fonts/apercu/apercu-regular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

@font-face {
    font-family: "apercu-bold";
    src: url("../../fonts/apercu/apercu-bold.eot");
    src: url("../../fonts/apercu/apercu-bold.eot?#iefix") format("embedded-opentype"),
         url("../../fonts/apercu/apercu-bold.woff") format("woff"),
         url("../../fonts/apercu/apercu-bold.woff2") format("woff2"),
         url("../../fonts/apercu/apercu-bold.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;

}

/* * * * * * * * * * * * * * * * * * * *
*  Tiempos font
* * * * * * * * * * * * * * * * * * * */

@font-face {
    font-family: "tiempos-bold-italic";
    src: url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.eot");
    src: url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.eot?#iefix") format("embedded-opentype"),
         url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.woff") format("woff"),
         url("../../fonts/tiempos/TiemposTextWeb-BoldItalic.woff2") format("woff2");
    font-style: normal;
    font-weight: normal;
}

@font-face {
   font-family: "tiempos-bold";
   src: url("../../fonts/tiempos/TiemposTextWeb-Bold.eot");
   src: url("../../fonts/tiempos/TiemposTextWeb-Bold.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/tiempos/TiemposTextWeb-Bold.woff") format("woff"),
        url("../../fonts/tiempos/TiemposTextWeb-Bold.woff2") format("woff2");
   font-style: normal;
   font-weight: normal;
}

@font-face {
   font-family: "tiempos-italic";
   src: url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.eot");
   src: url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.woff") format("woff"),
        url("../../fonts/tiempos/TiemposTextWeb-RegularItalic.woff2") format("woff2");
   font-style: normal;
   font-weight: normal;
}

@font-face {
   font-family: "tiempos-regular";
   src: url("../../fonts/tiempos/TiemposTextWeb-Regular.eot");
   src: url("../../fonts/tiempos/TiemposTextWeb-Regular.eot?#iefix") format("embedded-opentype"),
        url("../../fonts/tiempos/TiemposTextWeb-Regular.woff") format("woff"),
        url("../../fonts/tiempos/TiemposTextWeb-Regular.woff2") format("woff2");
   font-weight: normal;
   font-style: normal;
}
