// Custom table class styles
table.tableList {
  width:100%;
  overflow: auto;
  tr {
    @include font-size(18px, 1.4);
  }
  tr:first-child{
    filter: brightness(85%);
  }
  tr:nth-child(odd){
    background: #F4F2E6;
  }
  td {
    padding: 7px;
    @include font-size(18px, 1.4);
    line-height: 1.25em;
  }
}
