.breadcrumbs__wrapper {
  bottom: -18px;
  position: absolute;
  width: 100%;

  &::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 0), $white 70%);
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
  }
}

.breadcrumbs {
  @include font-size(13px, 1.4);

  background: $white;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 10px 13px;
  text-transform: uppercase;
  white-space: nowrap;

  a {
    border-radius: 3px;
    color: $link-body;
    padding: 5px 2px 5px 5px;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    &:focus {
      background: $link-body;
      color: #fff;
      outline: 0;
    }
  }
}

.breadcrumbs__separator {
  color: #b4b4b4;
}

.breadcrumbs__current-page {
  padding-left: 2px;
}
