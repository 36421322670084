// Use modfier to specify usage and to avoid !important if possible.
.swiper-container {
  &--slider {
    width: 100%;

    &.swiper-container-horizontal>.swiper-pagination-bullets {
      bottom: 0;
    }

    .swiper-pagination-bullet-active {
      background-color: $swiper-pagination-active;
    }

    figure {
      margin-bottom: 16px;
    }

    img {
      height: auto !important;
      margin-left: 0 !important;
      margin-right: 0 !important;
      max-width: 100% !important;
      width: 100% !important;
    }
  }

  &--content-slider {

    .domain-lh-emil-kodamera-dev & {

      @include breakpoint(desktop) {
        margin-left: -4rem !important;
        margin-right: -4rem !important;
        max-width: none;
        width: calc(100% + 8rem);
      }
    }
  }

  &--with-caption {
    margin-bottom: 4rem !important;

    .content__caption {
      font-family: tiempos-regular, georgia, serif;
      margin-bottom: 1.8rem;

      .domain-lh-emil-kodamera-dev & {

        @include breakpoint(desktop) {
          margin-left: 4rem;
          margin-right: 4rem;
        }
      }
    }
  }
}
