.google-map {
  height: 500px;
  margin-bottom: 50px;

  &--dynamic-map {
    float: left;
    width: 100%;
  }

  &__marker {
    display: none;
  }
}
