.date-box-container {
  @include breakpoint("tablet") {
    padding-left: 60px;
  }
}

.date-box {
  background: #fff;
  border-radius: 2px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 50px;
}

.date-box__pusher {
  min-height: auto;
  padding-left: 60px;

  @include breakpoint("tablet") {
    padding-left: 0;
  }

  .event__list-item & {
    min-height: 75px;
    padding-left: 60px;
  }

  .post__link {
    color: $link-title;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.date-box__year {
  background: $date-box-top-background;
  color: $date-box-top-text;
  font-size: 10px;
  font-weight: bold;
  line-height: 1em;
  padding: 2px 0 1px;
}

.date-box__day {
  @include font-size(22px, 1.2);

  border: 1px solid #e5e5e5;
  border-width: 0 1px;
  font-weight: bold;
}

.date-box__month {
  @include font-size(14px, 1.5);

  border: 1px solid #e5e5e5;
  border-radius: 0 0 2px 2px;
  border-width: 0 1px 1px;
  color: #666;
  text-transform: uppercase;
}
