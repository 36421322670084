// Responisve embed.
.responsive-embed {
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
  margin-bottom: 1.5rem;
  iframe {
    height: 100%;
    position: absolute;
    width: 100%;
  }
  + .content__caption {
    margin: -1.2rem 0 1.5rem;
  }
}

p + .responsive-embed {
  margin-top: -1rem;
}

.cookieconsent-optout-marketing{
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  display: block;
  .overlay{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.8);
  }
  .play-icon {
    @include image-2x("../../images/video-play-button@2x.png", 35px, 35px);
    background-repeat: no-repeat;
    width: 35px;
    height: 35px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    @include breakpoint("phablet") {
      width: 72px;
      height: 72px;
      background-size: 72px 72px;
    }
  }
  .top {
    position: absolute;
    z-index: 101;
    top: 10px;
    left: 10px;
    display: flex;
    align-items: center;
    .logo {
      width: 50px;
      height: 50px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      img {
        border-radius: 50%;
      }
    }
    .title {
      font-size: 1.2rem;
      line-height: 1.3;
      padding-right: 10px;
      @include breakpoint("tablet") {
        font-size: 1.4rem;
      }
    }
  }
  .consent-wrapper{
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    bottom: 2%;
    z-index: 101;
    color: black;
    font-weight: 600;
    font-size: 1rem;
    line-height: 1.8rem;
    width: 90%;
    text-align: center;
    @include breakpoint("phablet-mini") {
      width: 80%;
    }
    @include breakpoint("tablet") {
      font-size: 1.4rem;
      bottom: 5%;
    }
    .cta {
      margin-top: 5px;
      font-size: 1rem;
      display: block;
      padding: 5px 20px !important;
      @include breakpoint("tablet") {
        font-size: 1.5rem;
        padding: 15px 30px !important;
        max-width: 430px;
        margin: 0 auto;
        margin-top: 15px;
      }
    }
  }
}
