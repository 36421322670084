.skip-to-content__link {
  background: $link-primary;
  clip: rect(1px, 1px, 1px, 1px);
  color: #fff;
  display: block;
  height: 1px;
  overflow: hidden;
  padding: 5px;
  position: absolute;
  text-decoration: none;

  &:focus {
    clip: auto;
    height: auto;
    outline: 0;
    overflow: visible;
    position: static;
  }
}
