// PRIMARY PUSHERS -------------------------------------

.primary-pushers__container {
  float: left;
  position: absolute;
  top: -540px;
  width: 100%;

  @include breakpoint("pusher-switch-1") {
    top: -440px;
  }

  @include breakpoint("pusher-switch-3") {
    top: -350px;
  }

  &.primary-pushers__count--1 {
    top: -200px;

    @include breakpoint("pusher-switch-1") {
      top: -130px;
    }
  }

  &.primary-pushers__count--2 {
    top: -360px;

    @include breakpoint("pusher-switch-1") {
      padding-bottom: -440px;
    }

    @include breakpoint("pusher-switch-3") {
      padding-bottom: -350px;
    }
  }

}

.primary-pushers__sort-link {
  background: rgba(0, 0, 0, .42);
  border-bottom-right-radius: 3px;
  color: #fff;
  display: none;
  font-size: 15px;
  left: 0;
  padding: 6px 10px;
  position: absolute;
  text-decoration: none;
  top: 0;

  .primary-pushers__container:hover & {
    display: inline-block;
  }

  &:hover {
    text-decoration: underline;
  }
}

.header {

  .front &.primary-pushers__count--1 {
    padding-bottom: 200px;

    @include breakpoint("pusher-switch-1") {
      padding-bottom: 130px;
    }
  }

  .front &.primary-pushers__count--2 {
    padding-bottom: 360px;

    @include breakpoint("pusher-switch-3") {
      padding-bottom: 350px;
    }
  }

  .front &.primary-pushers__count--3 {
    padding-bottom: 540px;

    @include breakpoint("pusher-switch-1") {
      padding-bottom: 440px;
    }

    @include breakpoint("pusher-switch-3") {
      padding-bottom: 350px;
    }
  }

}

.wrapper--content {

  .front & {

    position: relative;

    @include breakpoint("pusher-switch-1") {
      padding-top: 140px;
    }

    &.primary-pushers__count--1 {
      padding-top: 50px;

      @include breakpoint("pusher-switch-1") {
        padding-top: 210px;
      }

      @include breakpoint("pusher-switch-2") {
        padding-top: 320px;
      }

      @include breakpoint("pusher-switch-3") {
        padding-top: 370px;
      }

    }

    &.primary-pushers__count--2 {
      padding-top: 120px;

      @include breakpoint("pusher-switch-1") {
        padding-top: 30px;
      }

      @include breakpoint("pusher-switch-3") {
        padding-top: 130px;
      }
    }

    &.primary-pushers__count--3 {
      padding-top: 160px;

      @include breakpoint("pusher-switch-1") {
        padding-top: 140px;
      }
    }

  }
}

.primary-pusher {
  background-color: $black;
  background-position: center;
  background-size: cover;
  border-bottom: 2px solid $white;
  position: relative;

  .primary-pusher__grid--1-1 & {
    border-bottom: 0;
  }

  @include breakpoint("pusher-switch-2") {

    .primary-pusher__grid--3-2 & {
      border-right: 2px solid $white;
    }

    .primary-pusher__grid--3-2 &,
    .primary-pusher__grid--3-3 & {
      border-bottom: 0;
      float: left;
      width: 50%;
    }
  }

  @include breakpoint("pusher-switch--count-2") {

    .primary-pusher__grid--2-1 & {
      border-right: 2px solid $white;
    }

    .primary-pusher__grid--2-1 &,
    .primary-pusher__grid--2-2 & {
      float: left;
      width: 50%;
    }
  }

  @include breakpoint("pusher-switch-3") {

    .primary-pusher__grid--3-1 & {
      border-bottom: 0;
      border-right: 2px solid $white;
      float: left;
      width: 50%;
    }

    .primary-pusher__grid--3-2 & {
      border-bottom: 2px solid $white;
    }

    .primary-pusher__grid--3-2 &,
    .primary-pusher__grid--3-3 & {
      border-right: 0;
      float: left;
      width: 50%;
    }
  }

}

.primary-pusher__inner-wrapper {
  // scss-lint:disable all
  background: -moz-linear-gradient(top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 80%);
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 80%);
  background: linear-gradient(to bottom, rgba(0, 0, 0, .75) 0%, rgba(0, 0, 0, 0) 80%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#00000000', GradientType=0);
  // scss-lint:enable all

  display: flex;
  height: 220px;
  padding: 15px;
  position: relative;

  .primary-pusher__show-only-image & {
    align-items: center;
    opacity: 0;
  }

  @include breakpoint("pusher-switch-1") {
    height: 180px;

    .primary-pusher__grid--1-1 & {
      height: 320px;
    }
  }

  @include breakpoint("pusher-switch--count-2") {
    .primary-pusher__grid--2-1 &,
    .primary-pusher__grid--2-2 & {
      height: 362px;
    }
  }

  @include breakpoint("pusher-switch-2") {

    .primary-pusher__grid--1-1 & {
      height: 420px;
      padding: 34px;
    }

    .primary-pusher__grid--3-1 &,
    .primary-pusher__grid--3-2 &,
    .primary-pusher__grid--3-3 & {
      height: 272px;
    }

    .primary-pusher__grid--3-1 & {
      padding: 34px;
    }
  }

  @include breakpoint("pusher-switch-3") {

    .primary-pusher__grid--1-1 &,
    .primary-pusher__grid--2-1 &,
    .primary-pusher__grid--2-2 & {
      height: 460px;
      padding: 24px;
    }

    .primary-pusher__grid--3-1 & {
      height: 462px;
    }

    .primary-pusher__grid--3-2 &,
    .primary-pusher__grid--3-3 & {
      height: 230px;
      padding: 24px;
    }

  }

}

.primary-pusher__title {
  @include font-size(26px, 1.2);

  color: $white;
  font-weight: 400;
  margin-bottom: 8px;
  word-wrap: break-word;

  @include breakpoint("pusher-switch-2") {

    margin-bottom: 10px;

    .primary-pusher__grid--1-1 & {
      @include font-size(42px, 1.2);
    }

    .primary-pusher__grid--3-1 & {
      @include font-size(42px, 1.2);
    }

    .primary-pusher__grid--3-2 &,
    .primary-pusher__grid--3-3 & {
      @include font-size(28px, 1.2);
    }
  }

  @include breakpoint("pusher-switch-3") {

    .primary-pusher__grid--2-1 &,
    .primary-pusher__grid--2-2 & {
      @include font-size(42px, 1.2);
    }

    .primary-pusher__grid--1-1 &,
    .primary-pusher__grid--3-1 & {
      @include font-size(48px, 1);
    }

  }

  .primary-pusher__show-only-image & {
    display: none;
  }

}

.primary-pusher__byline {
  @include font-size(14px, 1.3);

  color: $white;
  margin-bottom: 8px;
  opacity: .9;

  @include breakpoint("pusher-switch-2") {
    @include font-size(17px, 1.4);
    margin-bottom: 16px;
  }

  .primary-pusher__show-only-image & {
    display: none;
  }
}

.primary-pusher__link {
  background: $primary-pusher-cta-background;
  color: $primary-pusher-cta-color;
  display: inline-block;
  padding: 12px 18px 8px;
  position: relative;
  text-decoration: none;

  &:hover {
    background: $primary-pusher-cta-background-hover;
  }

  @include breakpoint("pusher-switch-2") {
    padding: 12px 18px 8px;
  }

  .primary-pusher__show-only-image & {
    display: none;
  }
}

.primary-pusher__content {
  width: 100%;

  .primary-pusher__grid--1-1 & {

    @include breakpoint("pusher-switch--count-2") {
      left: 50%;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .primary-pusher__grid--2-1 &,
  .primary-pusher__grid--2-2 &,
  .primary-pusher__grid--3-1 & {
    @include breakpoint("pusher-switch-2") {
      padding: 30px;
    }
  }

}


// SECONDARY PUSHERS -----------------------------------

.secondary-pushers__list-item {
  margin-bottom: 20px;
}

.secondary-pushers__list-item--last {
  margin-bottom: 0;
}

.secondary-pusher__wrapper {
  background: $secondary-pusher-background;
  padding: 20px;
  position: relative;
}

.secondary-pusher__corner {
  border-left: 26px solid $secondary-pusher-corner;
  border-top: 26px solid $white;
  position: absolute;
  right: 0;
  top: 0;
}

.secondary-pusher__link {
  color: $secondary-pusher-color;
  float: left;
  text-decoration: none;
  width: 100%;

  &:hover .secondary-pusher__label {
    text-decoration: underline;
  }
}

.secondary-pusher__prefix {
  @include font-size(16px, 1.4);

  color: $secondary-pusher-prefix;
}

.secondary-pusher__title {
  @include font-size(26px, 1.1);

  color: $secondary-pusher-color;
  font-weight: bold;
}

.secondary-pusher__suffix {
  @include font-size(15px, 1.4);

  color: $secondary-pusher-color;
}

.secondary-pusher__label {
  float: right;
  margin-top: 20px;

  &::before {
    @include font-size(15px, 1);

    content: "\e60d";
    float: right;
    font-family: "Lindholmen icons";
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-weight: normal;
    margin-left: 6px;
    position: relative;
    text-transform: none;
    top: 6px;
  }
}
